import { Box, Button, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const EmptyCheckoutPage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%'
}));

export const EmptyCheckoutPageDivider = styled(Divider)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '60%'
  },
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values.md / 4
  },
  '&::before, &::after': {
    top: 0
  }
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(12),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(15)
}));
