import MenuIcon from '@mui/icons-material/MenuOutlined';
import { Badge as MuiBadge, Box, Button, IconButton, Toolbar as MuiToolbar } from '@mui/material';
import { styled } from '@mui/system';

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values.sm
  }
}));

export const MenuIconStyled = styled(MenuIcon)(() => ({
  marginRight: 'auto'
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

export const ShoppingCartButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  margin: 0,
  padding: 0
}));

export const LanguageSwitchButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '&:hover': {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`
  },
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: theme.spacing(2),
  minWidth: theme.spacing(5),
  maxWidth: theme.spacing(5),
  margin: theme.spacing(1),
  padding: 0
}));

export const InvisibleBox = styled(Box)(() => ({
  marginRight: 'auto',
  visibility: 'hidden'
}));
