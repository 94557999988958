import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CHECKOUT_RESPONSE } from '../../../../shared/constants';

const getCheckoutResponse = () => {
  let resp;
  try {
    resp = JSON.parse(Cookies.get(CHECKOUT_RESPONSE));
  } catch (e) {
    resp = {};
  } finally {
    Cookies.remove(CHECKOUT_RESPONSE);
  }
  return resp;
};

export const useCheckoutResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    const { type } = getCheckoutResponse();
    if (type) {
      handler(type);
    }
  }, [checked, handler]);
};
