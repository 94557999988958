import { Slide, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { ALERT_SEVERITY_NONE } from '../../../../shared/constants';
import { getWrappedComponentDisplayName } from '../../../../shared/utils';
import { AlertStyled, BackdropStyled } from './withSnackbar.styles';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center'
};

const autoHideDuration = 30000; // 30s

const TransitionComponent = (props) => <Slide {...props} direction="down" />;

const withSnackbar = () => (WrappedComponent) => {
  const WithSnackbar = () => {
    const [state, setState] = useState({
      open: false,
      severity: ALERT_SEVERITY_NONE,
      message: ''
    });

    const onClose = () => {
      setState({ open: false });
    };

    const setSnackbar = ({ message, severity }) => {
      setState({
        open: true,
        severity,
        message
      });
    };

    return (
      <>
        <BackdropStyled open={state.open} onClick={onClose}>
          <Snackbar
            anchorOrigin={anchorOrigin}
            open={state.open}
            autoHideDuration={autoHideDuration}
            TransitionComponent={TransitionComponent}
          >
            <AlertStyled onClose={onClose} severity={state.severity}>
              {state.message}
            </AlertStyled>
          </Snackbar>
        </BackdropStyled>
        <WrappedComponent setSnackbar={setSnackbar} />
      </>
    );
  };

  WithSnackbar.displayName = `WithSnackbar(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithSnackbar;
};

export { withSnackbar };
