import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isNilOrEmpty } from '../../../../../../shared/utils';
import MenubarTab from './MenubarTab';
import { MenubarTabs as MenubarTabsStyled } from './MenubarTabs.styles';

const pattern = /\/([a-zA-Z]*)\/{0,1}$/;

const menus = {
  about: 'about',
  menu: 'menu',
  faq: 'faq',
  contact: 'contact'
};

const getPathname = ({ pathname }) => {
  const groups = pattern.exec(pathname);

  if (isNilOrEmpty(groups) || groups.length < 2) {
    // no matches: no selected tab
    return false;
  }

  const matchedValue = groups[1];

  if (isNilOrEmpty(matchedValue)) {
    // matching root, landing page: show as about tab is slected
    return menus.about;
  }

  if (Object.keys(menus).filter((key) => menus[key] === matchedValue).length !== 1) {
    // no matches: no selected tab
    return false;
  }

  return matchedValue;
};

const MenubarTabs = ({ t }) => {
  const [menu, setMenu] = useState(getPathname(useLocation()));

  const changeMenu = (newMenu) => () => {
    setMenu(newMenu);
  };

  return (
    <MenubarTabsStyled value={menu} textColor="primary" indicatorColor="primary" centered>
      <MenubarTab value={menus.about} label={t('menu.about')} onClick={changeMenu(menus.about)} href="/" />
      <MenubarTab value={menus.menu} label={t('menu.menu')} onClick={changeMenu(menus.menu)} href="/menu" />
      <MenubarTab value={menus.faq} label={t('menu.faq')} onClick={changeMenu(menus.faq)} href="/faq" />
      <MenubarTab value={menus.contact} label={t('menu.contact')} onClick={changeMenu(menus.contact)} href="/contact" />
    </MenubarTabsStyled>
  );
};

MenubarTabs.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(MenubarTabs);
