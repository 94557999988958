import { Box, CardContent } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCardContent = styled(CardContent)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
  '&:last-child': {
    paddingBottom: theme.spacing(1)
  }
}));

export const MenuAddToCartSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0.5)
}));

export const MenuSizeCustomizeSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around'
}));
