import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { dollarize } from '../../../../shared/utils';
import ExpandMore from '../../shared/ExpandMore';
import {
  CheckoutItemActionContainer,
  CheckoutItemCardContent as CheckoutItemCardContentStyled,
  DeleteIconButton
} from './CheckoutItemCardContent.styles';

const CheckoutItemCardContent = ({ name, price, expanded, customize, handleExpandClick, handleRemoveFromCartClick }) => (
  <CheckoutItemCardContentStyled>
    <Typography component="span" variant="body1" sx={{ lineHeight: 1 }}>
      {name}
    </Typography>
    <Typography component="span" variant="button" color="text.secondary">
      {dollarize(price)}
    </Typography>
    <CheckoutItemActionContainer>
      {customize && (
        <ExpandMore expand={expanded} onClick={handleExpandClick} sx={{ marginRight: 'auto' }}>
          <ExpandMoreIcon />
        </ExpandMore>
      )}
      <DeleteIconButton onClick={handleRemoveFromCartClick}>
        <DeleteIcon />
      </DeleteIconButton>
    </CheckoutItemActionContainer>
  </CheckoutItemCardContentStyled>
);

CheckoutItemCardContent.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  customize: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleRemoveFromCartClick: PropTypes.func.isRequired
};

export default CheckoutItemCardContent;
