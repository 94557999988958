import PropTypes from 'prop-types';
import React from 'react';
import { Header as HeaderStyled, HeaderBackground, HeaderTypography } from './Header.styles';

const Header = ({ text, src, alt }) => (
  <HeaderStyled>
    <HeaderTypography variant="h5" color="secondary.light">
      {text}
    </HeaderTypography>
    <HeaderBackground component="img" src={src} alt={alt} />
  </HeaderStyled>
);

Header.propTypes = {
  text: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Header;
