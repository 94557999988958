import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CONTACT_RESPONSE } from '../../../../shared/constants';

const getContactResponse = () => {
  let resp;
  try {
    resp = JSON.parse(Cookies.get(CONTACT_RESPONSE));
  } catch (e) {
    resp = {};
  } finally {
    Cookies.remove(CONTACT_RESPONSE);
  }
  return resp;
};

export const useContactResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    const { type, name, email, message } = getContactResponse();
    if (type) {
      handler({ type, name, email, message });
    }
  }, [checked, handler]);
};
