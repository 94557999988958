import PropTypes from 'prop-types';
import React from 'react';
import { CreditLink as CreditLinkStyled } from './CreditLink.styles';

const CreditLink = ({ href, alt }) => (
  <CreditLinkStyled href={href} component="a" alt={alt} target="_blank" rel="noopener noreferrer">
    {alt}
  </CreditLinkStyled>
);

CreditLink.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default CreditLink;
