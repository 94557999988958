import { FormControlLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { dollarize, isNilOrEmpty, isOutOfStock, isStockLow, tWithNamespace } from '../../../../shared/utils';
import { MenuCheckbox } from './MenuCardOption.styles';

const MenuCardOption = ({ t, id, disabled, checked, i18nId, i18nNs, n, prices, stock, handleOptionClick }) => {
  // t wrapper that passes namespace
  const tt = tWithNamespace(t, i18nNs);

  const getOptionText = () => {
    // interpolate n if exists
    const optionText = !n ? tt(i18nId) : tt(i18nId, { n });
    // check stock status
    const outOfStock = !isNilOrEmpty(stock) && isOutOfStock(stock);
    const lowStock = !isNilOrEmpty(stock) && isStockLow(stock);
    // interpolate price if exists
    if (outOfStock) {
      return `${optionText} - ${t('menuPage.outOfStock')}`;
    }
    if (lowStock) {
      return `${optionText} - ${t('menuPage.lowStock', { stock })}`;
    }
    // use up-to-date pricing
    const price = prices[id];
    return !price ? optionText : `${optionText} - ${dollarize(price)}`;
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={<MenuCheckbox checked={checked} size="small" inputProps={{ 'data-id': id }} onClick={handleOptionClick} />}
      label={
        <Typography variant="body2" color="textSecondary">
          {getOptionText()}
        </Typography>
      }
    />
  );
};

MenuCardOption.defaultProps = {
  disabled: true,
  checked: true,
  n: null,
  price: null,
  stock: null
};

MenuCardOption.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  i18nId: PropTypes.string.isRequired,
  i18nNs: PropTypes.string.isRequired,
  n: PropTypes.number,
  price: PropTypes.number,
  prices: PropTypes.shape({}).isRequired,
  stock: PropTypes.number,
  handleOptionClick: PropTypes.func.isRequired
};

export default withTranslation()(MenuCardOption);
