import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isProductionMode } from '../shared/utils';
import dakGalbi from './dak-galbi';
import gamjaTang from './gamja-tang';
import jokbal from './jokbal';
import laGalbi from './la-galbi';
import logistics from './logistics';
import osam from './osam';
import en from './translation.en.json';
import ko from './translation.ko.json';

const DETECTION_OPTIONS = {
  order: ['querystring', 'localStorage', 'navigator'],
  caches: ['localStorage']
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProductionMode(),
    load: 'languageOnly',
    detection: DETECTION_OPTIONS,
    // lng: navigator.language || navigator.userLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        laGalbi: laGalbi.en,
        osam: osam.en,
        dakGalbi: dakGalbi.en,
        gamjaTang: gamjaTang.en,
        jokbal: jokbal.en,
        translation: {
          ...en,
          stripe: logistics.en
        }
      },
      ko: {
        laGalbi: laGalbi.ko,
        osam: osam.ko,
        dakGalbi: dakGalbi.ko,
        gamjaTang: gamjaTang.ko,
        jokbal: jokbal.ko,
        translation: {
          ...ko,
          stripe: logistics.ko
        }
      }
    }
  });

export default i18n;
