import React, { useEffect, useState } from 'react';
import { pageview } from 'react-ga';
import { useLocation } from 'react-router-dom';
import { getWrappedComponentDisplayName, isNilOrEmpty } from '../../../../shared/utils';

const withGAPageview = (pathnameOverride) => (WrappedComponent) => {
  const WithGAPageview = (props) => {
    const { pathname } = useLocation();
    const [logged, setLogged] = useState(false);

    useEffect(() => {
      if (logged) {
        return;
      }
      setLogged(true);

      // trim ending / if exists
      pageview(`/${pathname.replace(/\//, '')}`);

      // log pathname override if exists
      if (!isNilOrEmpty(pathnameOverride)) {
        pageview(pathnameOverride);
      }
    }, [logged, pathname]);

    return <WrappedComponent {...props} />;
  };

  WithGAPageview.displayName = `WithGAPageview(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithGAPageview;
};

export { withGAPageview };
