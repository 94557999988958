import PropTypes from 'prop-types';
import React from 'react';
import { Link as LinkStyled, Typography as TypographyStyled } from './Link.styles';

const Link = ({ href, text, openNewTab, variant, sx }) => (
  <TypographyStyled variant={variant} component="span" sx={sx}>
    {openNewTab ? (
      <LinkStyled href={href} component="a" target="_blank" rel="noopener noreferrer">
        {text}
      </LinkStyled>
    ) : (
      <LinkStyled href={href} component="a">
        {text}
      </LinkStyled>
    )}
  </TypographyStyled>
);

Link.defaultProps = {
  openNewTab: true,
  variant: 'caption',
  sx: {}
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  openNewTab: PropTypes.bool,
  variant: PropTypes.string,
  sx: PropTypes.shape({})
};

export default Link;
