import { Box, Collapse, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCardOptionCollapse = styled(Collapse)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5)
}));

export const MenuCardOptionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

export const MenuCardOptionRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(0.5)
}));

export const MenuCardOptionCol = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 'auto'
}));
