import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import '../i18n/i18n';
import { NONCE } from '../shared/constants';
import { isProductionMode } from '../shared/utils';
import AppRouter from './components/router/AppRouter';
import './styles/styles.scss';
import { theme } from './themes/AppThemes';

// retrieve nonce set by server
const nonce = Cookies.get(NONCE);

// fallback meta tag nonce
const cacheOptions = {
  key: 'gogit',
  nonce: nonce || document.querySelector('meta[property="csp-nonce"]').content,
  prepend: true
};

// create @emotion cache with server generated nonce stored in cookie
const cache = createCache(cacheOptions);

// remove nonce from coockie
Cookies.remove(NONCE);

ReactGA.initialize(process.env.GA_TRACKING_CODE, {
  debug: !isProductionMode(),
  testMode: !isProductionMode()
});

const App = () => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouter />
    </ThemeProvider>
  </CacheProvider>
);

ReactDOM.render(<App />, document.getElementById('app'));
