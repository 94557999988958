import PropTypes from 'prop-types';
import React from 'react';
import { PaperItem as PaperItemStyled, PaperItemIcon, PaperItemTypography } from './PaperItem.styles';

const PaperItem = ({ text, src, alt }) => (
  <PaperItemStyled>
    <PaperItemTypography variant="body1" color="secondary.light">
      {text}
    </PaperItemTypography>
    <PaperItemIcon variant="square" src={src} alt={alt} />
  </PaperItemStyled>
);

PaperItem.propTypes = {
  text: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default PaperItem;
