import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Footer = styled(Box)(({ theme }) => ({
  fontFamily: 'Roboto,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginBottom: theme.spacing(0.5)
  },
  padding: theme.spacing(1.5, 0)
}));
