import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const OrderPageContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  }
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(12),
  margin: theme.spacing(1)
}));

export const OrderHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4)
}));
