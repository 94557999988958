import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { PAYMENT_PROMO } from '../../../../shared/constants';
import { dollarize } from '../../../../shared/utils';
import { OrderTableCell, OrderTableContainer } from './OrderTable.styles';

const OrderTable = ({ t, items }) => {
  const subtotal = items.map(({ price, quantity }) => price * quantity).reduce((a, b) => a + b, 0);
  // const taxRate = 5; // GST 5%
  // const afterTax = Math.ceil(subtotal * taxRate);
  // const total = Math.ceil(subtotal / 100 + afterTax / 10000);

  const getPromoItem = ({ id, price, quantity }) => (
    <TableRow key={id}>
      <OrderTableCell>{t('orderPage.table.promo')}</OrderTableCell>
      <OrderTableCell align="right">{dollarize((price / 100))}</OrderTableCell>
      <OrderTableCell align="right">{quantity}</OrderTableCell>
      <OrderTableCell align="right">{dollarize(((price * quantity) / 100))}</OrderTableCell>
    </TableRow>
  );

  const getLineItem = ({ id, description, price, quantity }) => (
    <TableRow key={id}>
      <OrderTableCell>{description}</OrderTableCell>
      <OrderTableCell align="right">{dollarize(price / 100)}</OrderTableCell>
      <OrderTableCell align="right">{quantity}</OrderTableCell>
      <OrderTableCell align="right">{dollarize((price * quantity) / 100)}</OrderTableCell>
    </TableRow>
  );

  return (
    <OrderTableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <OrderTableCell>{t('orderPage.table.description')}</OrderTableCell>
            <OrderTableCell align="right">{t('orderPage.table.price')}</OrderTableCell>
            <OrderTableCell align="right">{t('orderPage.table.quantity')}</OrderTableCell>
            <OrderTableCell align="right">{t('orderPage.table.sum')}</OrderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (item.id === PAYMENT_PROMO ? getPromoItem(item) : getLineItem(item)))}
          {/*
          <TableRow>
            <OrderTableCell rowSpan={3} />
            <OrderTableCell colSpan={2}>{t('orderPage.table.subtotal')}</OrderTableCell>
            <OrderTableCell align="right">{dollarize(subtotal / 100)}</OrderTableCell>
          </TableRow>
          <TableRow>
            <OrderTableCell colSpan={2}>{t('orderPage.table.tax')}</OrderTableCell>
            <OrderTableCell align="right">{dollarize(afterTax / 10000)}</OrderTableCell>
          </TableRow>
          */}
          <TableRow>
            <OrderTableCell rowSpan={1} />
            <OrderTableCell colSpan={2}>{t('orderPage.table.total')}</OrderTableCell>
            <OrderTableCell align="right">{dollarize(subtotal / 100)}</OrderTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </OrderTableContainer>
  );
};

OrderTable.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number
    })
  ).isRequired
};

export default withTranslation()(OrderTable);
