import { Link as MuiLink, Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/system';

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    letterSpacing: '0.02em'
  }
}));

export const Link = styled(MuiLink)(() => ({
  color: 'inherit',
  textDecoration: 'none'
}));
