import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { isNilOrEmpty } from '../../../../../../shared/utils';

const DrawerListItem = ({ icon, text, href, onClick, children }) => {
  if (!isNilOrEmpty(href)) {
    return (
      <ListItem button component="a" href={href}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {children}
      </ListItem>
    );
  }
  if (!isNilOrEmpty(onClick)) {
    return (
      <ListItem button onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {children}
      </ListItem>
    );
  }
  return null;
};

DrawerListItem.defaultProps = {
  icon: null,
  href: '',
  onClick: null,
  children: null
};

DrawerListItem.propTypes = {
  icon: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.shape({})
};

export default DrawerListItem;
