import { isProductionMode } from '../../shared/utils';
import products from './products.json';
import stripeId from './stripe-id.json';
import translation from './translation.json';

translation.en.products = isProductionMode() ? products.prod : products.test;
translation.en.stripeId = isProductionMode() ? stripeId.prod.en : stripeId.test.en;
translation.ko.stripeId = isProductionMode() ? stripeId.prod.ko : stripeId.test.ko;

export default translation;
