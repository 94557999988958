import axios from 'axios';
import { useEffect, useState } from 'react';
import { AXIOS_CONFIG } from '../../../../shared/constants';

export const useInventoryData = (setProgress) => {
  const [data, setData] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (fetched) {
      return;
    }
    setFetched(true);

    setProgress(true);

    const getInventoryData = async () => {
      try {
        const resp = await axios.get('/api/inventories', {
          ...AXIOS_CONFIG
        });
        setData(resp.data);
      } catch (e) {
        setData({ error: true });
      } finally {
        setProgress(false);
      }
    };

    getInventoryData();
  }, [data, fetched, setData, setProgress]);

  return data;
};
