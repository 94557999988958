import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Collapse, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaqCollapsibleHeader, FaqDivider, FaqItem as FaqItemStyled } from './FaqItem.styles';

const FaqItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const handleExpandClick = () => {
    setOpen(!open);
  };

  return (
    <FaqItemStyled>
      <FaqCollapsibleHeader>
        <Typography variant="h6" color="secondary.light" onClick={handleExpandClick} sx={{ lineHeight: '1.2' }}>
          {question}
        </Typography>
        <IconButton onClick={handleExpandClick}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
      </FaqCollapsibleHeader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Typography variant="body2" color="secondary.light">
          {answer}
        </Typography>
      </Collapse>
      <FaqDivider sx={{ padding: `${open ? 4 : 0}px 0px` }} />
    </FaqItemStyled>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
};

export default FaqItem;
