import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCartOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { isOutOfStock, OPEN_FOR_ORDER } from '../../../../shared/utils';
import { MenuAddToCartButton as MenuAddToCartButtonStyled } from './MenuAddToCartButton.styles';

const MenuAddToCartButton = ({ handleAddToCart, stock }) => (
  <MenuAddToCartButtonStyled disabled={!OPEN_FOR_ORDER || isOutOfStock(stock)} onClick={handleAddToCart}>
    <AddShoppingCartIcon fontSize="large" />
  </MenuAddToCartButtonStyled>
);

MenuAddToCartButton.defaultProps = {
  stock: 0
};

MenuAddToCartButton.propTypes = {
  handleAddToCart: PropTypes.func.isRequired,
  stock: PropTypes.number
};

export default MenuAddToCartButton;
