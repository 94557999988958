import { TableContainer, TableCell } from '@mui/material';
import { styled } from '@mui/system';

export const OrderTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5, 1)
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1, 2)
  }
}));

export const OrderTableContainer = styled(TableContainer)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(4)
}));
