import { LOGISTICS_DELIVERY } from '../../shared/constants';

const KEY = 'gogit-logistics';

const dispatchStorageEvent = () => {
  // displatch manual storage event for the current tab/window to pick up the event
  window.dispatchEvent(new Event('storage'));
};

export const getLogistics = () => localStorage.getItem(KEY) || LOGISTICS_DELIVERY;

export const setLogistics = (logistics) => {
  console.log('>> setLogistics:', logistics);
  localStorage.setItem(KEY, logistics);
  dispatchStorageEvent();
};
