import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  MenuCardDivider as MenuCardDividerStyled,
  MenuCardDividerLogo as MenuCardDividerLogoStyled
} from './MenuCardDivider.styles';

const MenuCardDivider = ({ t }) => (
  <MenuCardDividerStyled light>
    <MenuCardDividerLogoStyled variant="square" src={t('favicon.src')} alt={t('favicon.alt')} />
  </MenuCardDividerStyled>
);

MenuCardDivider.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(MenuCardDivider);
