import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Logo as LogoStyled } from './Logo.styles';

const Logo = ({ t }) => (
  <LogoStyled href="/">
    <Avatar variant="square" src={t('logo.src')} alt={t('logo.alt')} sx={{ width: 56, height: 42 }} />
  </LogoStyled>
);

Logo.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Logo);
