import { Alert, Box } from '@mui/material';
import { styled } from '@mui/system';

export const AlertBannerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  }
}));

export const AlertBanner = styled(Box)(() => ({
  width: '100%'
}));

export const AlertStyled = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  width: '100%',
  '& .MuiAlert-icon': {
    alignItems: 'center'
  },
  '& .MuiAlert-action': {
    alignItems: 'center'
  }
}));
