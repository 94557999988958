import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ElevationScroll from '../../../../shared/ElevationScroll';
import { Menubar as MenubarStyled } from './Menubar.styles';
import MenubarTabs from './MenubarTabs';

const Menubar = (props) => {
  const { offset } = props;
  const [menubarHeight, setMenubarHeight] = useState(0);
  const menubarRef = useRef(null);

  useEffect(() => {
    if (menubarHeight !== 0) {
      return;
    }
    if (menubarRef && menubarRef.current) {
      setMenubarHeight(menubarRef.current.offsetHeight);
    }
  }, [menubarHeight]);

  return (
    <>
      <ElevationScroll>
        <MenubarStyled ref={menubarRef} color="offwhite" elevation={0} position="fixed" sx={{ top: offset }}>
          <MenubarTabs />
        </MenubarStyled>
      </ElevationScroll>
      {/* empty div to give some extra space, same as menubar, so contents won't get put behind the menubar */}
      <Box sx={{ minHeight: menubarHeight }} />
    </>
  );
};

Menubar.propTypes = {
  offset: PropTypes.number.isRequired
};

export default Menubar;
