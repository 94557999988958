import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { event } from 'react-ga';
import { withTranslation } from 'react-i18next';
import {
  GA_EVENT_CHECKOUT_CUSTOMIZE_CLICK,
  GA_EVENT_CHECKOUT_DELETE,
  GA_EVENT_CHECKOUT_OPTION_CHANGE
} from '../../../../shared/constants';
import { tWithNamespace } from '../../../../shared/utils';
import { removeFromCart, updateCart } from '../../../storage/cart';
import MenuCardOptions from '../../menu-page/menu-card/MenuCardOptions';
import {
  CheckoutItemCard as CheckoutItemCardStyled,
  CheckoutItemCardContainer as CheckoutItemCardContainerStyled
} from './CheckoutItemCard.styles';
import CheckoutItemCardContent from './CheckoutItemCardContent';
import CheckoutItemCardMedia from './CheckoutItemCardMedia';

// toggle option by the given id
const toggleOption = (options, id, checked) => options.map((option) => (option.id === id ? { ...option, checked } : option));

const CheckoutItemCard = ({ t, ns, id, price, size, prices, options }) => {
  const [state, setState] = useState({
    expanded: false,
    price,
    options
  });

  // t wrapper that passes namespace
  const tt = tWithNamespace(t, ns);

  // prepare input for menu card media and content
  const name = `${tt('name')} - ${tt(size)}`;
  const src = tt('image.src');
  const alt = tt('image.alt');
  const products = tt('products', { returnObjects: true });

  const handleExpandClick = () => {
    setState({ ...state, expanded: !state.expanded });
    event(GA_EVENT_CHECKOUT_CUSTOMIZE_CLICK);
  };

  const handleOptionClick = (e) => {
    if (!e || !e.target) {
      return;
    }

    const dataId = e.target.getAttribute('data-id');
    if (!dataId) {
      return;
    }

    const optionPrice = prices[dataId] || 0;
    const newPrice = e.target.checked ? state.price + optionPrice : state.price - optionPrice;
    const newOptions = toggleOption(state.options, dataId, e.target.checked);
    setState({
      ...state,
      price: newPrice,
      options: newOptions
    });

    // update cart
    updateCart(id, {
      ns,
      price: newPrice,
      size,
      prices,
      options: newOptions
    });
    event(GA_EVENT_CHECKOUT_OPTION_CHANGE);
  };

  const handleRemoveFromCartClick = () => {
    removeFromCart(id);
    event(GA_EVENT_CHECKOUT_DELETE);
  };

  return (
    <CheckoutItemCardStyled>
      <CheckoutItemCardContainerStyled>
        <CheckoutItemCardMedia src={src} alt={alt} />
        <CheckoutItemCardContent
          name={name}
          price={state.price}
          expanded={state.expanded}
          customize={state.options.length > 0}
          handleExpandClick={handleExpandClick}
          handleRemoveFromCartClick={handleRemoveFromCartClick}
        />
      </CheckoutItemCardContainerStyled>
      <MenuCardOptions
        prices={products[size].price}
        options={state.options}
        expanded={state.expanded}
        handleOptionClick={handleOptionClick}
      />
    </CheckoutItemCardStyled>
  );
};

CheckoutItemCard.propTypes = {
  t: PropTypes.func.isRequired,
  ns: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  prices: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default withTranslation()(CheckoutItemCard);
