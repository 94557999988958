import { Avatar, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCardDivider = styled(Divider)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down('md')]: {
    width: '30%'
  },
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values.md / 4
  },
  '&::before, &::after': {
    top: 0
  },
}));

export const MenuCardDividerLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  filter: 'grayscale(100%)'
}));
