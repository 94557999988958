import React, { useEffect, useRef, useState } from 'react';
import ElevationScroll from '../../shared/ElevationScroll';
import { useScreenSizeDetector } from '../../shared/hooks/useScreenSizeDetector';
import { AppBar as AppBarStyled } from './AppBar.styles';
import Menubar from './menu/menu-bar/Menubar';
import MenuDrawer from './menu/menu-drawer/MenuDrawer';
import Toolbar from './toolbar/Toolbar';
import ToolbarOffset from './toolbar/ToolbarOffset';

const AppBar = () => {
  const [state, setState] = useState({
    drawer: false,
    toolbarHeight: 0
  });
  const appbarRef = useRef(null);
  const isSmallScreen = useScreenSizeDetector();

  /* Calculate AppBar height via appBarRef and pass it down to Menubar to offset
   * Menubar drawer under the main AppBar. */
  useEffect(() => {
    if (state.toolbarHeight !== 0) {
      return;
    }
    if (appbarRef && appbarRef.current) {
      setState({ ...state, toolbarHeight: appbarRef.current.offsetHeight });
    }
  }, [state]);

  const toggleDrawer = (open) => () => {
    setState({ ...state, drawer: open });
  };

  return (
    <>
      <ElevationScroll>
        <AppBarStyled ref={appbarRef} elevation={0}>
          <Toolbar toggleDrawer={toggleDrawer} />
        </AppBarStyled>
      </ElevationScroll>
      <ToolbarOffset />
      {isSmallScreen ? (
        <MenuDrawer drawer={state.drawer} toggleDrawer={toggleDrawer} />
      ) : (
        <Menubar offset={state.toolbarHeight} />
      )}
    </>
  );
};

export default AppBar;
