import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { event } from 'react-ga';
import { withTranslation } from 'react-i18next';
import {
  DELIVERY_THRESHOLD,
  GA_EVENT_CHECKOUT_CLICK,
  LOGISTICS_DELIVERY,
  LOGISTICS_PICK_UP_EVERGREEN,
  LOGISTICS_PICK_UP_MARDALOOP,
  POST
} from '../../../shared/constants';
import { deliveryWeekSaturday, isAfterCutOff, tWithNamespace } from '../../../shared/utils';
import { clearCart, getCart } from '../../storage/cart';
import { CheckoutButton as CheckoutButtonStyled } from './CheckoutButton.styles';

// prepare price id input: an array of price ids (may be dups), e.g. ['id1', 'id2']
const getPriceId = (cart, t, logistics) => {
  try {
    const priceIds = cart
      .map(({ ns, size, options }) => {
        // t wrapper that passes namespace
        const tt = tWithNamespace(t, ns);
        // get stripe id based on i18n namespace
        const stripeId = tt('stripeId', { returnObjects: true });
        return [stripeId[size].base, options.filter(({ checked }) => checked).map(({ id }) => stripeId[size][id])].flat();
      })
      .flat();

    if (logistics === LOGISTICS_DELIVERY) {
      // add delivery fee stripe price id if total is < pre-defined delivery threshold
      const total = cart.map(({ price }) => price).reduce((a, b) => a + b, 0);
      if (total < DELIVERY_THRESHOLD) {
        priceIds.push(t('stripe.deliveryFee'));
      } else {
        priceIds.push(t('stripe.freeDelivery'));
      }
    } else if (logistics === LOGISTICS_PICK_UP_EVERGREEN) {
      priceIds.push(t('stripe.pickUp.evergreen'));
    } else if (logistics === LOGISTICS_PICK_UP_MARDALOOP) {
      priceIds.push(t('stripe.pickUp.mardaLoop'));
    }

    return JSON.stringify(priceIds);
  } catch (e) {
    clearCart();
    return JSON.stringify([]);
  }
};

const CheckoutButton = ({ t, i18n, logistics, disabled }) => {
  const getState = () => ({
    cart: getCart(),
    lang: i18n.resolvedLanguage,
    logistics
  });

  const [state, setState] = useState(getState());

  const handleCheckoutClick = () => {
    // update cart and language before submit
    setState(getState());
    event(GA_EVENT_CHECKOUT_CLICK);
  };

  return (
    <form action="/api/checkout" method={POST}>
      <input type="hidden" name="priceId" value={getPriceId(state.cart, t, state.logistics)} />
      <input type="hidden" name="deliveryDate" value={isAfterCutOff() ? deliveryWeekSaturday(1) : deliveryWeekSaturday()} />
      <input type="hidden" name="locale" value={state.lang} />
      <input type="hidden" name="logistics" value={state.logistics} />
      <CheckoutButtonStyled variant="outlined" type="submit" disabled={disabled} onClick={handleCheckoutClick}>
        {t('checkoutPage.checkout')}
      </CheckoutButtonStyled>
    </form>
  );
};

CheckoutButton.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    resolvedLanguage: PropTypes.string
  }).isRequired,
  logistics: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default withTranslation()(CheckoutButton);
