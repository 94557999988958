import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { GA_PAGEVIEW_404, HELMET_TITLE_KEY_404 } from '../../../shared/constants';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { ButtonStyled, ErrorIcon } from './NotFoundPage.styles';

const NotFoundPage = ({ t }) => (
  <Page sx={{ justifyContent: 'center' }}>
    <ErrorIcon fontSize="large" />
    <Typography variant="h6" color="secondary.light">
      {t('notFoundPage.message')}
    </Typography>
    <ButtonStyled variant="outlined" component="a" href="/">
      {t('notFoundPage.backToHome')}
    </ButtonStyled>
  </Page>
);

NotFoundPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withGAPageview(GA_PAGEVIEW_404)(withTranslation()(withHelmetTitle(HELMET_TITLE_KEY_404)(NotFoundPage)));
