import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';

export const MenuSizeContainer = styled(Box)(() => ({
  marginRight: 'auto'
}));

export const MenuSizeFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

export const MenuSizeSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-outlined': {
    padding: theme.spacing(0.5, 1),
    minWidth: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(12)
    }
  }
}));

export const MenuSizeItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  minHeight: theme.spacing(4)
}));
