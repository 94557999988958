import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const SitemapHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

export const SitemapRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1)
}));

export const SitemapColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center'
}));
