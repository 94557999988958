import { Avatar, CardMedia } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCardImage = styled(Avatar)(() => ({
  width: '100%',
  height: '100%'
}));

export const MenuCardComingSoonImage = styled(Avatar)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0
}));

export const MenuCardMedia = styled(CardMedia)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values.sm
  }
}));
