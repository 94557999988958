import { CardHeader } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCardHeader = styled(CardHeader)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
  '& .MuiCardHeader-content': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
