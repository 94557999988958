import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ALERT_SEVERITY_INFO } from '../../../shared/constants';
import AlertBanner from './AlertBanner';
import Link from './Link';

const renderEnAnnouncement = () => null;
// (
//   <AlertBanner
//     message={
//       <Box>
//         <Typography variant="body2" component="span">
//           ✨ We&apos;re at the{' '}
//         </Typography>
//         <Link
//           href="https://www.farmersmakersmarket.ca/"
//           text="Farmers & Makers Market"
//           variant="button"
//           sx={{ textDecoration: 'underline' }}
//         />
//         <Typography variant="body2" component="span">
//           {' '}
//           located in Marda Loop every Saturday 10-2pm. Swing by and say hello. 👋
//         </Typography>
//       </Box>
//     }
//     severity={ALERT_SEVERITY_INFO}
//   />
// );

const renderKoAnnouncement = () => (
  <AlertBanner
    message={
      <Box>
        <Typography variant="body2" component="span">
          ✨ 매주 토요일 오전 10시에서 오후 2시까지 Marda Loop 에 위치한{' '}
        </Typography>
        <Link
          href="https://www.farmersmakersmarket.ca/"
          text="Farmers & Makers Market"
          variant="button"
          sx={{ textDecoration: 'underline' }}
        />
        <Typography variant="body2" component="span">
          {' '}
          에서 고깃을 만날 수 있습니다. 👋
        </Typography>
      </Box>
    }
    severity={ALERT_SEVERITY_INFO}
  />
);

const AnnouncementBanner = ({ i18n }) => {
  if (!i18n) {
    return null;
  }
  if (!i18n.language) {
    return null;
  }
  if (i18n.language.startsWith('en')) {
    return renderEnAnnouncement();
  }
  if (i18n.language.startsWith('ko')) {
    return renderKoAnnouncement();
  }
  return null;
};

AnnouncementBanner.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(AnnouncementBanner);
