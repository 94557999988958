import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_EN, LANGUAGE_KO } from '../../../../../shared/constants';
import { getLanguage } from '../../../../../shared/utils';
import { getCartLength } from '../../../../storage/cart';
import { useScreenSizeDetector } from '../../../shared/hooks/useScreenSizeDetector';
import { useStorage } from '../../../shared/hooks/useStorage';
import Logo from './logo/Logo';
import {
  Badge as BadgeStyled,
  InvisibleBox,
  LanguageSwitchButton,
  MenuIconStyled,
  ShoppingCartButton as ShoppingCartButtonStyled,
  Toolbar as ToolbarStyled
} from './Toolbar.styles';

const Toolbar = ({ i18n, toggleDrawer }) => {
  const [cartLength, setCartLength] = useState(getCartLength());
  const isSmallScreen = useScreenSizeDetector();

  useStorage(() => {
    if (cartLength === getCartLength()) {
      return;
    }
    setCartLength(getCartLength());
  });

  const changeLanguage = () => {
    if (getLanguage(i18n) === LANGUAGE_EN) {
      i18n.changeLanguage(LANGUAGE_KO);
    } else {
      i18n.changeLanguage(LANGUAGE_EN);
    }
  };

  return (
    <ToolbarStyled>
      {isSmallScreen ? (
        <MenuIconStyled fontSize="large" onClick={toggleDrawer(true)} />
      ) : (
        <InvisibleBox>
          <ShoppingCartIcon fontSize="large" />
          <LanguageSwitchButton variant="outlined" color="secondary" component="button">
            {getLanguage(i18n)}
          </LanguageSwitchButton>
        </InvisibleBox>
      )}
      <Logo />
      <Box sx={{ marginLeft: 'auto' }}>
        {!isSmallScreen && (
          <LanguageSwitchButton variant="outlined" color="secondary" component="button" onClick={changeLanguage}>
            {getLanguage(i18n) === LANGUAGE_EN ? '한' : 'EN'}
          </LanguageSwitchButton>
        )}
        <BadgeStyled badgeContent={cartLength} color="secondary">
          <ShoppingCartButtonStyled component="a" href="/checkout">
            <ShoppingCartIcon fontSize="large" />
          </ShoppingCartButtonStyled>
        </BadgeStyled>
      </Box>
    </ToolbarStyled>
  );
};

Toolbar.propTypes = {
  i18n: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    changeLanguage: PropTypes.func
  }).isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default withTranslation()(Toolbar);
