import EmailIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ContactContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  }
}));

export const ContactHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

export const ContactForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm * 0.6,
    maxWidth: theme.breakpoints.values.sm * 0.6
  },
  justifyContent: 'center',
  padding: theme.spacing(2, 0)
}));

export const ContactFormTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1, 0)
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(12)
}));

export const ContactDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: '80%',
    maxWidth: '80%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm * 0.5,
    maxWidth: theme.breakpoints.values.sm * 0.5
  },
  '&::before, &::after': {
    top: 0
  },
  padding: theme.spacing(2, 0)
}));

export const ContactMethodContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: theme.spacing(1)
  }
}));

export const ContactEmailIcon = styled(EmailIcon)(({ theme }) => ({
  color: theme.palette.secondary.light
}));

export const ContactPhoneIcon = styled(PhoneIcon)(({ theme }) => ({
  color: theme.palette.secondary.light
}));

export const ContactFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0)
}));
