import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { event } from 'react-ga';
import { withTranslation } from 'react-i18next';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_SUCCESS,
  CONTACT_SUCCESS,
  GA_EVENT_CONTACT_FAILURE
} from '../../../shared/constants';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { withSnackbar } from '../shared/hoc/withSnackbar';
import { useContactResponse } from '../shared/hooks/useContactResponse';
import Link from '../shared/Link';
import ContactForm from './ContactForm';
import {
  ContactContainer,
  ContactDivider,
  ContactEmailIcon,
  ContactFooter,
  ContactHeader,
  ContactMethodContainer,
  ContactPhoneIcon
} from './ContactPage.styles';

const ContactPage = ({ t, setSnackbar }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  useContactResponse(({ type, ...data }) => {
    const severity = type === CONTACT_SUCCESS ? ALERT_SEVERITY_SUCCESS : ALERT_SEVERITY_ERROR;

    if (type !== CONTACT_SUCCESS) {
      setFormData(data);
      // log GA metrics
      event({ ...GA_EVENT_CONTACT_FAILURE, label: type });
    }

    setSnackbar({
      message: t(`notification.${type}`),
      severity
    });
  });

  const handleFormDataChange = (type) => (e) => {
    setFormData({ ...formData, [type]: e.target.value });
  };

  return (
    <Page>
      <ContactContainer>
        <ContactHeader variant="h5" color="secondary.light">
          {t('contactPage.header')}
        </ContactHeader>
        <ContactHeader variant="body2" color="secondary.light">
          {t('contactPage.subheader')}
        </ContactHeader>
        <ContactForm {...formData} handleFormDataChange={handleFormDataChange} />
        <ContactDivider>
          <Typography variant="caption" color="secondary.light">
            {t('contactPage.reachUsAt')}
          </Typography>
        </ContactDivider>
        <ContactFooter>
          <ContactMethodContainer>
            <ContactEmailIcon />
            <Link
              href={`mailto:${t('contactPage.contact.email')}`}
              text={t('contactPage.contact.email')}
              sx={{ lineHeight: 0 }}
            />
          </ContactMethodContainer>
          <ContactMethodContainer>
            <ContactPhoneIcon />
            <Link
              href={`tel:+1${t('contactPage.contact.phone')}`}
              text={t('contactPage.contact.phone')}
              openNewTab={false}
              sx={{ lineHeight: 0 }}
            />
          </ContactMethodContainer>
        </ContactFooter>
      </ContactContainer>
    </Page>
  );
};

ContactPage.propTypes = {
  t: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired
};

export default withGAPageview()(withSnackbar()(withTranslation()(withHelmetTitle()(ContactPage))));
