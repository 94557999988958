import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ALERT_SEVERITY_INFO, ALERT_SEVERITY_WARNING } from '../../../shared/constants';
import {
  CUT_OFF_DAY,
  DELIVERY_DAY,
  isAfterCutOff,
  isBeforeCutOff,
  NEXT_CUT_OFF_DAY,
  NEXT_DELIVERY_DAY,
  OPEN_FOR_ORDER,
  removeAllNonLetters
} from '../../../shared/utils';
import AlertBanner from '../shared/AlertBanner';
import { AlertBannerContainer } from '../shared/AlertBanner.styles';
import AnnouncementBanner from '../shared/AnnouncementBanner';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { useScreenSizeDetector } from '../shared/hooks/useScreenSizeDetector';
import Header from './header/Header';
import { ButtonStyled } from './LandingPage.styles';
import PaperItem from './paper-item/PaperItem';

const iconMapping = {
  warning: <LocalShippingIcon fontSize="inherit" />,
  info: <LocalShippingIcon fontSize="inherit" />
};

const LandingPage = ({ t }) => {
  const isSmallScreen = useScreenSizeDetector();

  const translate = ({ mon, day, dow }) => ({
    mon: t(`${isSmallScreen ? 'mon' : 'month'}.${mon}`),
    day: t(day),
    dow: t(`${isSmallScreen ? 'dowShort' : 'dow'}.${dow}`)
  });

  return (
    <Page>
      <AlertBannerContainer>
        <AnnouncementBanner />
        {/* after cut off alert */}
        {OPEN_FOR_ORDER && isAfterCutOff() && (
          <AlertBanner
            title={t('landingPage.alert.afterCutOff', translate(NEXT_DELIVERY_DAY))}
            message={
              <Box>
                <Typography variant="body2">{t('landingPage.alert.cutOff', translate(NEXT_CUT_OFF_DAY))}</Typography>
                <Typography variant="body2">{t('landingPage.alert.delivery', translate(NEXT_DELIVERY_DAY))}</Typography>
                <Typography variant="body2">{t('landingPage.alert.deliveryWindow')}</Typography>
              </Box>
            }
            severity={ALERT_SEVERITY_WARNING}
            iconMapping={iconMapping}
          />
        )}
        {/* before cut off alert */}
        {OPEN_FOR_ORDER && isBeforeCutOff() && (
          <AlertBanner
            title={t('landingPage.alert.beforeCutOff', translate(DELIVERY_DAY))}
            message={
              <Box>
                <Typography variant="body2">{t('landingPage.alert.cutOff', translate(CUT_OFF_DAY))}</Typography>
                <Typography variant="body2">{t('landingPage.alert.delivery', translate(DELIVERY_DAY))}</Typography>
                <Typography variant="body2">{t('landingPage.alert.deliveryWindow')}</Typography>
              </Box>
            }
            severity={ALERT_SEVERITY_INFO}
            iconMapping={iconMapping}
          />
        )}
        {/* order closed alert */}
        {!OPEN_FOR_ORDER && <AlertBanner message={t('landingPage.alert.openingSoon')} severity={ALERT_SEVERITY_INFO} />}
      </AlertBannerContainer>
      <ButtonStyled variant="outlined" component="a" href="/menu">
        {t('landingPage.goToMenu')}
      </ButtonStyled>
      <Header {...t('landingPage.header', { returnObjects: true })} />
      {t('landingPage.steps', { returnObjects: true }).map((item) => (
        <PaperItem key={removeAllNonLetters(item.alt)} {...item} />
      ))}
      <ButtonStyled variant="outlined" component="a" href="/menu">
        {t('landingPage.goToMenu')}
      </ButtonStyled>
    </Page>
  );
};

LandingPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withGAPageview()(withTranslation()(withHelmetTitle()(LandingPage)));
