export const PRODUCTION = 'production';
export const GOGIT_AT_NESTEDTREE_COM = 'gogit@nestedtree.com';

// Iubenda Policy URLs
const policyId = process.env.IUBENDA_POLICY_ID;
export const IUBENDA_PRIVACY_POLICY_URL = `https://www.iubenda.com/api/privacy-policy/${policyId}`;
export const IUBENDA_TERMS_AND_CONDITIONS_URL = `https://www.iubenda.com/api/terms-and-conditions/${policyId}`;

// Logistics
export const LOGISTICS_DELIVERY = 'checkoutPage.logistics.delivery';
export const LOGISTICS_PICK_UP_EVERGREEN = 'checkoutPage.logistics.pickupEvergreen';
export const LOGISTICS_PICK_UP_MARDALOOP = 'checkoutPage.logistics.pickupMardaLoop';

// Delivery Fee and Threshold
export const DELIVERY_FEE = parseInt(process.env.DELIVERY_FEE, 10);
export const DELIVERY_THRESHOLD = parseInt(process.env.DELIVERY_THRESHOLD, 10);

// Axios config
export const AXIOS_CONFIG = {
  timeout: 30000 // 30s
};

export const OUT_OF_STOCK_THRESHOLD = 0;
export const LOW_STOCK_THRESHOLD = 2;
export const DDB_DEFAULT = 'default';

// Helmet Title Mapping Keys
export const HELMET_TITLE_KEY_ABOUT = '/';
export const HELMET_TITLE_KEY_MENU = '/menu';
export const HELMET_TITLE_KEY_ORDER = '/order';
export const HELMET_TITLE_KEY_CHECKOUT = '/checkout';
export const HELMET_TITLE_KEY_FAQ = '/faq';
export const HELMET_TITLE_KEY_CONTACT = '/contact';
export const HELMET_TITLE_KEY_CREDITS = '/credits';
export const HELMET_TITLE_KEY_TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const HELMET_TITLE_KEY_PRIVACY_POLICY = '/privacy-policy';
export const HELMET_TITLE_KEY_SITEMAP = '/sitemap';
export const HELMET_TITLE_KEY_404 = '/404';

// Google Analytics
export const GA_PAGEVIEW_ORDER = '/order';
export const GA_PAGEVIEW_404 = '/404';
// menu
export const GA_EVENT_MENU_ADD_TO_CART = { category: 'menu', action: 'add-to-cart' };
export const GA_EVENT_MENU_CUSTOMIZE_CLICK = { category: 'menu', action: 'customize-click' };
export const GA_EVENT_MENU_SIZE_CHANGE = { category: 'menu', action: 'size-change' };
export const GA_EVENT_MENU_OPTION_CHANGE = { category: 'menu', action: 'option-change' };
// checkout
export const GA_EVENT_CHECKOUT_CLICK = { category: 'checkout', action: 'checkout-click' };
export const GA_EVENT_CHECKOUT_FAILURE = { category: 'checkout', action: 'failure' };
export const GA_EVENT_CHECKOUT_CUSTOMIZE_CLICK = { category: 'checkout', action: 'customize-click' };
export const GA_EVENT_CHECKOUT_OPTION_CHANGE = { category: 'checkout', action: 'option-change' };
export const GA_EVENT_CHECKOUT_DELETE = { category: 'checkout', action: 'delete' };
// contact
export const GA_EVENT_CONTACT_SUBMIT = { category: 'contact', action: 'submit' };
export const GA_EVENT_CONTACT_FAILURE = { category: 'contact', action: 'failure' };

// Contact Input Threshold
export const CONTACT_NAME_MAX_LENGTH = 72;
export const CONTACT_EMAIL_MAX_LENGTH = 320;
export const CONTACT_MESSAGE_MAX_LENGTH = 2500;

// Supported Languages
export const LANGUAGE_EN = 'en';
export const LANGUAGE_KO = 'ko';

// dayjs lib format
export const DAYJS_YYC_TIMEZONE = 'America/Edmonton';
export const DAYJS_DATE_FORMAT = 'YYYY-MM-DD';
export const DAYJS_DAY_OF_WEEK = 'dddd';
export const DAYJS_WEEK = 'week';
export const DAYJS_MONTH = 'MMM';
export const DAYJS_DAY = 'D';

// Payment Status
export const PAYMENT_PAID = 'paid';
export const PAYMENT_UNPAID = 'unpaid';
export const PAYMENT_CANCELLED = 'canceled';
export const PAYMENT_REFUNDED = 'refunded';
export const PAYMENT_PROMO = 'promo';

// Alert Severity
export const ALERT_SEVERITY_NONE = '';
export const ALERT_SEVERITY_ERROR = 'error';
export const ALERT_SEVERITY_WARNING = 'warning';
export const ALERT_SEVERITY_INFO = 'info';
export const ALERT_SEVERITY_SUCCESS = 'success';

// Cookie Keys
export const NONCE = 'nonce';
export const CONTACT_RESPONSE = 'contactResponse';
export const CHECKOUT_RESPONSE = 'checkoutResponse';
export const CLEAR_CART_RESPONSE = 'clearCartResponse';

// Checkout Response Code
export const CONTACT_SUCCESS = 'contactSuccess';
export const CONTACT_FAILURE = 'contactFailure';
export const CONTACT_FAILURE_INVALID_NAME = 'contactFailureInvalidName';
export const CONTACT_FAILURE_INVALID_EMAIL = 'contactFailureInvalidEmail';
export const CONTACT_FAILURE_INVALID_MESSAGE = 'contactFailureInvalidMessage';
export const CHECKOUT_FAILURE = 'generalError';
export const CHECKOUT_BAD_REQUEST_NO_PRICE_ID = 'checkoutBadRequestNoPriceId';

// Menu Namespaces
export const NS_DAK_GALBI = 'dakGalbi';
export const NS_GAMJA_TANG = 'gamjaTang';
export const NS_JOKBAL = 'jokbal';
export const NS_OSAM = 'osam';
export const NS_LA_GALBI = 'laGalbi';

// Route Methods
export const DELETE = 'DELETE';
export const GET = 'GET';
export const PATCH = 'PATCH';
export const POST = 'POST';
