import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const CheckoutButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: 'auto'
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1)
  },
  minWidth: theme.spacing(12),
  height: theme.spacing(5)
}));
