import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getWrappedComponentDisplayName } from '../../../../shared/utils';

const withIubendaPolicy = (content) => (WrappedComponent) => {
  const WithIubendaPolicy = () => (
    <>
      <Helmet>
        <link type="text/css" rel="stylesheet" href="https://www.iubenda.com/assets/privacy_policy.css" />
      </Helmet>
      {content && <Box dangerouslySetInnerHTML={{ __html: content }} />}
    </>
  );

  WithIubendaPolicy.displayName = `WithIubendaPolicy(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithIubendaPolicy;
};

export { withIubendaPolicy };
