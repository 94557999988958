import PropTypes from 'prop-types';
import React from 'react';
import { CheckoutItemCardMedia as CheckoutItemCardMediaStyled } from './CheckoutItemCardMedia.styles';

const CheckoutItemCardMedia = ({ src, alt }) => <CheckoutItemCardMediaStyled component="img" image={src} alt={alt} />;

CheckoutItemCardMedia.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default CheckoutItemCardMedia;
