import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {
  HELMET_TITLE_KEY_404,
  HELMET_TITLE_KEY_ABOUT,
  HELMET_TITLE_KEY_CHECKOUT,
  HELMET_TITLE_KEY_CONTACT,
  HELMET_TITLE_KEY_CREDITS,
  HELMET_TITLE_KEY_FAQ,
  HELMET_TITLE_KEY_MENU,
  HELMET_TITLE_KEY_ORDER,
  HELMET_TITLE_KEY_PRIVACY_POLICY,
  HELMET_TITLE_KEY_SITEMAP,
  HELMET_TITLE_KEY_TERMS_AND_CONDITIONS
} from '../../../../shared/constants';
import { getWrappedComponentDisplayName, isNilOrEmpty } from '../../../../shared/utils';

const i18nMapping = {
  [HELMET_TITLE_KEY_404]: 'title.404',
  [HELMET_TITLE_KEY_ABOUT]: 'title.about',
  [HELMET_TITLE_KEY_CHECKOUT]: 'title.checkout',
  [HELMET_TITLE_KEY_CONTACT]: 'title.contact',
  [HELMET_TITLE_KEY_CREDITS]: 'title.credits',
  [HELMET_TITLE_KEY_FAQ]: 'title.faq',
  [HELMET_TITLE_KEY_MENU]: 'title.menu',
  [HELMET_TITLE_KEY_ORDER]: 'title.order',
  [HELMET_TITLE_KEY_PRIVACY_POLICY]: 'title.privacyPolicy',
  [HELMET_TITLE_KEY_SITEMAP]: 'title.sitemap',
  [HELMET_TITLE_KEY_TERMS_AND_CONDITIONS]: 'title.termsAndConditions'
};

const withHelmetTitle = (override) => (WrappedComponent) => {
  const WithHelmetTitle = (props) => {
    const { t } = props;
    const { pathname } = useLocation();
    const key = isNilOrEmpty(override) ? `/${pathname.replace(/\//, '')}` : override;

    return (
      <>
        <Helmet>
          <title>{`${t('title.gogit')} - ${t(i18nMapping[key])}`}</title>
        </Helmet>
        <WrappedComponent {...props} />
      </>
    );
  };

  WithHelmetTitle.displayName = `WithHelmetTitle(${getWrappedComponentDisplayName(WrappedComponent)})`;

  WithHelmetTitle.propTypes = {
    t: PropTypes.func.isRequired
  };

  return WithHelmetTitle;
};

export { withHelmetTitle };
