import { createTheme } from '@mui/material/styles';
import RokaFontWoff2 from '../fonts/roka-bold.woff2';

export const theme = createTheme({
  typography: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'roka';
          src: url(${RokaFontWoff2}) format('woff2');
          font-weight: 400;
          font-style: normal;
        }
      `
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'roka'
        }
      }
    }
  },
  palette: {
    type: 'light',
    background: {
      default: '#ed4261'
    },
    primary: {
      main: '#ed4261'
    },
    secondary: {
      main: '#3a3b3c'
    },
    offwhite: {
      main: '#f7f7f7'
    }
  }
});
