import PropTypes from 'prop-types';
import React from 'react';
import { MenuCardHeader as MenuCardHeaderStyled } from './MenuCardHeader.styles';

const MenuCardHeader = ({ title }) => (
  <MenuCardHeaderStyled
    title={title}
    titleTypographyProps={{ variant: 'body1', noWrap: true }}
    // MVPv2 TODO: enable share button in MenuCardHeader
    // action={
    //   <IconButton>
    //     <ShareIcon />
    //   </IconButton>
    // }
  />
);

MenuCardHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default MenuCardHeader;
