import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  DAYJS_DAY,
  DAYJS_DAY_OF_WEEK,
  DAYJS_MONTH,
  GA_PAGEVIEW_ORDER,
  HELMET_TITLE_KEY_ORDER,
  LOGISTICS_DELIVERY,
  LOGISTICS_PICK_UP_EVERGREEN,
  LOGISTICS_PICK_UP_MARDALOOP,
  PAYMENT_CANCELLED,
  PAYMENT_PAID,
  PAYMENT_REFUNDED
} from '../../../shared/constants';
import AlertBanner from '../shared/AlertBanner';
import { AlertBannerContainer } from '../shared/AlertBanner.styles';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { withProgress } from '../shared/hoc/withProgress';
import { useOrderData } from '../shared/hooks/useOrderData';
import { useScreenSizeDetector } from '../shared/hooks/useScreenSizeDetector';
import OrderTable from './order-table/OrderTable';
import { OrderHeader, OrderPageContent } from './OrderPage.styles';

const OrderPage = ({ t, setProgress }) => {
  const { id } = useParams();
  const data = useOrderData(id, setProgress);
  const isSmallScreen = useScreenSizeDetector();

  const isCancelled = () => data.status === PAYMENT_CANCELLED;
  const isConfirmed = () => data.status === PAYMENT_PAID;
  const isRefunded = () => data.status === PAYMENT_REFUNDED;

  const renderError = () => (
    <>
      <AlertBannerContainer>
        <AlertBanner message={t('orderPage.banner.error')} severity={ALERT_SEVERITY_ERROR} />
      </AlertBannerContainer>
      <OrderPageContent>
        <Typography variant="body1" color="secondary.light">
          {t('orderPage.error')}
        </Typography>
      </OrderPageContent>
    </>
  );

  const renderPage = () => (
    <>
      <AlertBannerContainer>
        {isCancelled() && <AlertBanner message={t('orderPage.banner.orderCancelled')} severity={ALERT_SEVERITY_ERROR} />}
        {isRefunded() && <AlertBanner message={t('orderPage.banner.orderRefunded')} severity={ALERT_SEVERITY_INFO} />}
        {isConfirmed() && (
          <AlertBanner
            message={t('orderPage.banner.orderConfirmed')}
            severity={ALERT_SEVERITY_INFO}
            iconMapping={{
              info: <ThumbUpAltIcon fontSize="inherit" />
            }}
          />
        )}
      </AlertBannerContainer>
      <OrderPageContent>
        <OrderHeader variant={isSmallScreen ? 'h6' : 'h5'} color="secondary.light">
          {isCancelled() || isRefunded() ? t('orderPage.cancelled') : t('orderPage.thanks')}
        </OrderHeader>
        {isConfirmed() && (!data.logistics || data.logistics === LOGISTICS_DELIVERY) && (
          <Typography variant={isSmallScreen ? 'body1' : 'h6'} color="secondary.light">
            {t('orderPage.delivery', {
              mon: t(`month.${dayjs(data.deliveryDate).format(DAYJS_MONTH).toLowerCase()}`),
              day: dayjs(data.deliveryDate).format(DAYJS_DAY),
              dow: t(`dow.${dayjs(data.deliveryDate).format(DAYJS_DAY_OF_WEEK).toLowerCase()}`)
            })}
          </Typography>
        )}
        {isConfirmed() && data.logistics === LOGISTICS_PICK_UP_EVERGREEN && (
          <Typography variant={isSmallScreen ? 'body1' : 'h6'} color="secondary.light">
            {t('orderPage.pickUp', {
              mon: t(`month.${dayjs(data.deliveryDate).format(DAYJS_MONTH).toLowerCase()}`),
              day: dayjs(data.deliveryDate).format(DAYJS_DAY),
              dow: t(`dow.${dayjs(data.deliveryDate).format(DAYJS_DAY_OF_WEEK).toLowerCase()}`),
              community: 'Evergreen'
            })}
          </Typography>
        )}
        {isConfirmed() && data.logistics === LOGISTICS_PICK_UP_MARDALOOP && (
          <Typography variant={isSmallScreen ? 'body1' : 'h6'} color="secondary.light">
            {t('orderPage.pickUp', {
              mon: t(`month.${dayjs(data.deliveryDate).format(DAYJS_MONTH).toLowerCase()}`),
              day: dayjs(data.deliveryDate).format(DAYJS_DAY),
              dow: t(`dow.${dayjs(data.deliveryDate).format(DAYJS_DAY_OF_WEEK).toLowerCase()}`),
              community: 'Marda Loop'
            })}
          </Typography>
        )}
        <Typography variant={isSmallScreen ? 'body1' : 'h6'} color="secondary.light">
          {t('orderPage.orderNo', { orderId: data.orderId })}
        </Typography>
        <OrderTable items={data.items} />
      </OrderPageContent>
    </>
  );

  return (
    <Page>
      {data && data.error && renderError()}
      {data && !data.error && renderPage()}
    </Page>
  );
};

OrderPage.propTypes = {
  t: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

export default withGAPageview(GA_PAGEVIEW_ORDER)(
  withProgress()(withTranslation()(withHelmetTitle(HELMET_TITLE_KEY_ORDER)(OrderPage)))
);
