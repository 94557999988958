import { useEffect } from 'react';

export const useStorage = (handler) => {
  useEffect(() => {
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [handler]);
};
