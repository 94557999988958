import { Avatar, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const PaperItem = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(2, 2),
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const PaperItemTypography = styled(Typography)(() => ({
  textAlign: 'center',
  lineHeight: 1.2
}));

export const PaperItemIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginTop: theme.spacing(2)
}));
