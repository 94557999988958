import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ButtonStyled,
  EmptyCheckoutPage as EmptyCheckoutPageStyled,
  EmptyCheckoutPageDivider
} from './EmptyCheckoutPage.styles';

const EmptyCheckoutPage = ({ t }) => (
  <EmptyCheckoutPageStyled>
    <EmptyCheckoutPageDivider light>
      <Typography variant="body2" color="secondary.light">
        {t('checkoutPage.emptyCart')}
      </Typography>
    </EmptyCheckoutPageDivider>
    <ButtonStyled variant="outlined" component="a" href="/menu">
      {t('checkoutPage.goToMenu')}
    </ButtonStyled>
  </EmptyCheckoutPageStyled>
);

EmptyCheckoutPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(EmptyCheckoutPage);
