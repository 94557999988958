import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Header = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(1, 0),
  background: theme.palette.offwhite.main,
  color: theme.palette.primary.main,
  opacity: '0.80',
  fontFamily: 'roka',
  minWidth: '60%',
  maxWidth: '60%',
  textAlign: 'center'
}));

export const HeaderBackground = styled(Box)(() => ({
  width: '100%',
  boxShadow: '1px 1px 10px 2px'
}));
