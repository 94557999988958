import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';

export const CheckoutLogisticsSelect = styled(Select)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: '40%'
  },
  height: theme.spacing(5)
}));

export const CheckoutLogisticsMenuItem = styled(MenuItem)(() => ({
  whiteSpace: 'normal'
}));
