import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import Link from '../shared/Link';
import { SitemapColumn, SitemapHeader, SitemapRow } from './SitemapPage.styles';

const host = process.env.HOST;

const SitemapPage = ({ t }) => (
  <Page>
    <SitemapHeader variant="h5" color="secondary.light">
      {t('sitemap.header')}
    </SitemapHeader>
    <SitemapRow>
      <SitemapColumn>
        <Link href={`${host}/`} text={t('title.about')} variant="body2" openNewTab={false} />
        <Link href={`${host}/menu`} text={t('title.menu')} variant="body2" openNewTab={false} />
        <Link href={`${host}/checkout`} text={t('title.checkout')} variant="body2" openNewTab={false} />
        <Link href={`${host}/faq`} text={t('title.faq')} variant="body2" openNewTab={false} />
        <Link href={`${host}/contact`} text={t('title.contact')} variant="body2" openNewTab={false} />
        <Link href={`${host}/terms-and-conditions`} text={t('title.termsAndConditions')} variant="body2" openNewTab={false} />
        <Link href={`${host}/privacy-policy`} text={t('title.privacyPolicy')} variant="body2" openNewTab={false} />
        <Link href={`${host}/credits`} text={t('title.credits')} variant="body2" openNewTab={false} />
      </SitemapColumn>
    </SitemapRow>
  </Page>
);

SitemapPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withGAPageview()(withTranslation()(withHelmetTitle()(SitemapPage)));
