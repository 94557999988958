import { Box, Card } from '@mui/material';
import { styled } from '@mui/system';

export const CheckoutItemCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1),
  backgroundColor: theme.palette.offwhite.main,
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  }
}));

export const CheckoutItemCardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row'
}));
