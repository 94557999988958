import { nanoid } from 'nanoid';

const KEY = 'gogit-cart';

const dispatchStorageEvent = () => {
  // displatch manual storage event for the current tab/window to pick up the event
  window.dispatchEvent(new Event('storage'));
};

const setCart = (cart) => {
  localStorage.setItem(KEY, JSON.stringify(cart));
};

export const getCart = () => JSON.parse(localStorage.getItem(KEY)) || [];

export const getCartLength = () => getCart().length;

export const addToCart = (item) => {
  const cart = getCart();
  cart.push({
    id: nanoid(),
    ...item
  });
  setCart(cart);
  dispatchStorageEvent();
};

export const removeFromCart = (id) => {
  setCart(getCart().filter((item) => item.id !== id));
  dispatchStorageEvent();
};

export const updateCart = (id, item) => {
  const cart = getCart();
  const index = cart.findIndex((_) => _.id === id);
  cart[index] = {
    id,
    ...item
  };
  setCart(cart);
  dispatchStorageEvent();
};

export const clearCart = () => {
  localStorage.removeItem(KEY);
  dispatchStorageEvent();
};

export const isCartEmpty = () => getCartLength() === 0;
