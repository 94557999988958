import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ExpandMore from '../../shared/ExpandMore';
import { MenuCustomizeButton as MenuCustomizeButtonStyled } from './MenuCustomizeButton.styles';

const MenuCustomizeButton = ({ t, expanded, handleExpandClick }) => (
  <MenuCustomizeButtonStyled>
    <Typography variant="body1" onClick={handleExpandClick}>
      {t('menuPage.customize')}
    </Typography>
    <ExpandMore expand={expanded} onClick={handleExpandClick}>
      <ExpandMoreIcon />
    </ExpandMore>
  </MenuCustomizeButtonStyled>
);

MenuCustomizeButton.propTypes = {
  t: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired
};

export default withTranslation()(MenuCustomizeButton);
