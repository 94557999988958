import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { customAlphabet } from 'nanoid';
import { isEmpty, isNil } from 'ramda';
import {
  DAYJS_DATE_FORMAT,
  DAYJS_DAY,
  DAYJS_DAY_OF_WEEK,
  DAYJS_MONTH,
  DAYJS_WEEK,
  DAYJS_YYC_TIMEZONE,
  LANGUAGE_EN,
  LOW_STOCK_THRESHOLD,
  OUT_OF_STOCK_THRESHOLD,
  PRODUCTION
} from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

const nanoIdGenerator = customAlphabet('1234567890', 14);

export const OPEN_FOR_ORDER = process.env.OPEN_FOR_ORDER && process.env.OPEN_FOR_ORDER === 'true';

export const deliveryWeekSaturday = (wk = 0) =>
  dayjs()
    .tz(DAYJS_YYC_TIMEZONE)
    .add(wk, DAYJS_WEEK)
    .day(6)
    .format(DAYJS_DATE_FORMAT);

const DELIVERY_WEEK_THU = dayjs(deliveryWeekSaturday()).day(4);
const DELIVERY_WEEK_SAT = dayjs(deliveryWeekSaturday()).day(6);

const NEXT_DELIVERY_WEEK_THU = dayjs(deliveryWeekSaturday(1)).day(4);
const NEXT_DELIVERY_WEEK_SAT = dayjs(deliveryWeekSaturday(1)).day(6);

// Thursday of the service week
export const CUT_OFF_DAY = {
  mon: `${DELIVERY_WEEK_THU.format(DAYJS_MONTH).toLowerCase()}`,
  day: `${DELIVERY_WEEK_THU.format(DAYJS_DAY)}`,
  dow: `${DELIVERY_WEEK_THU.format(DAYJS_DAY_OF_WEEK).toLowerCase()}`
};
export const NEXT_CUT_OFF_DAY = {
  mon: `${NEXT_DELIVERY_WEEK_THU.format(DAYJS_MONTH).toLowerCase()}`,
  day: `${NEXT_DELIVERY_WEEK_THU.format(DAYJS_DAY)}`,
  dow: `${NEXT_DELIVERY_WEEK_THU.format(DAYJS_DAY_OF_WEEK).toLowerCase()}`
};

// Saturday of the service week
export const DELIVERY_DAY = {
  mon: `${DELIVERY_WEEK_SAT.format(DAYJS_MONTH).toLowerCase()}`,
  day: `${DELIVERY_WEEK_SAT.format(DAYJS_DAY)}`,
  dow: `${DELIVERY_WEEK_SAT.format(DAYJS_DAY_OF_WEEK).toLowerCase()}`
};
export const NEXT_DELIVERY_DAY = {
  mon: `${NEXT_DELIVERY_WEEK_SAT.format(DAYJS_MONTH).toLowerCase()}`,
  day: `${NEXT_DELIVERY_WEEK_SAT.format(DAYJS_DAY)}`,
  dow: `${NEXT_DELIVERY_WEEK_SAT.format(DAYJS_DAY_OF_WEEK).toLowerCase()}`
};

export const isAfterCutOff = () => {
  // cutoff by delivery service week Thursday midnight, 23:59:59
  let cutoff = dayjs(deliveryWeekSaturday()).tz(DAYJS_YYC_TIMEZONE);
  cutoff = cutoff.day(4).hour(23).minute(59).second(59);
  console.info(`Checking cutoff time: cutoff [${cutoff}] now [${dayjs().tz(DAYJS_YYC_TIMEZONE)}]`);
  return dayjs().tz(DAYJS_YYC_TIMEZONE).isAfter(cutoff);
};

export const isBeforeCutOff = () => !isAfterCutOff();

export const nanoid = () => {
  const id = nanoIdGenerator().toString();
  const beg = id.slice(0, 4);
  const mid = id.slice(4, 10);
  const end = id.slice(10, 14);
  return `${beg}-${mid}-${end}`;
};

export const isProductionMode = () => process.env.NODE_ENV === PRODUCTION;

export const isNilOrEmpty = (_) => isNil(_) || isEmpty(_);

export const dollarize = (price) => `$${(price || 0).toFixed(2)}`;

export const splitArrayInHalf = (a) => {
  if (!a) {
    return [];
  }

  const len = a.length;
  const half = Math.ceil(len / 2);
  return [a.slice(0, half), a.slice(half, len)].filter((_) => _.length !== 0);
};

export const getWrappedComponentDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const tWithNamespace = (t, ns) => (id, options) => {
  if (!options) {
    return t(id, { ns });
  }
  return t(id, { ns, ...options });
};

export const getCookieOptions = () => ({
  secure: isProductionMode(),
  httpOnly: false
});

export const getLanguage = (i18n) => {
  if (isNilOrEmpty(i18n.languages) || i18n.languages.length < 1) {
    return LANGUAGE_EN;
  }
  return i18n.languages[0] || LANGUAGE_EN;
};

// function to remove all non letters from a string, which then can be used as a key
export const removeAllNonLetters = (str) => encodeURIComponent(str).replace(/[^a-zA-Z-09]/g, '');

export const isOutOfStock = (stock) => stock <= OUT_OF_STOCK_THRESHOLD;

export const isStockLow = (stock) => stock > OUT_OF_STOCK_THRESHOLD && stock <= LOW_STOCK_THRESHOLD;
