import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  NS_DAK_GALBI,
  NS_GAMJA_TANG,
  NS_JOKBAL,
  NS_LA_GALBI,
  NS_OSAM
} from '../../../shared/constants';
import AlertBanner from '../shared/AlertBanner';
import { AlertBannerContainer } from '../shared/AlertBanner.styles';
import AnnouncementBanner from '../shared/AnnouncementBanner';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { withProgress } from '../shared/hoc/withProgress';
import { useInventoryData } from '../shared/hooks/useInventoryData';
import MenuCard from './menu-card/MenuCard';
import MenuCardDivider from './MenuCardDivider';
import { MenuCardWrapper } from './MenuPage.styles';

const menus = [NS_LA_GALBI, NS_OSAM, NS_DAK_GALBI, NS_GAMJA_TANG, NS_JOKBAL];

const isLastItem = (index) => index + 1 >= menus.length;

const MenuPage = ({ t, setProgress }) => {
  const inventory = useInventoryData(setProgress);

  const renderPage = (inv) => (
    <>
      <AlertBannerContainer>
        <AnnouncementBanner />
        <AlertBanner message={t('menuPage.banner')} severity={ALERT_SEVERITY_INFO} />
      </AlertBannerContainer>
      {menus.map((ns, index) => (
        <MenuCardWrapper key={ns}>
          <MenuCard ns={ns} inventory={inv} />
          {!isLastItem(index) && <MenuCardDivider />}
        </MenuCardWrapper>
      ))}
    </>
  );

  const renderError = () => (
    <>
      <AlertBannerContainer>
        <AlertBanner message={t('notification.generalError')} severity={ALERT_SEVERITY_ERROR} />
      </AlertBannerContainer>
      {renderPage({})}
    </>
  );

  return (
    <Page>
      {inventory && inventory.error && renderError()}
      {inventory && !inventory.error && renderPage(inventory)}
    </Page>
  );
};

MenuPage.propTypes = {
  t: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

export default withGAPageview()(withProgress()(withTranslation()(withHelmetTitle()(MenuPage))));
