import PropTypes from 'prop-types';
import { mergeAll } from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { isNilOrEmpty, isOutOfStock, tWithNamespace } from '../../../../shared/utils';
import { MenuCard as MenuCardStyled } from './MenuCard.styles';
import MenuCardContent from './MenuCardContent';
import MenuCardHeader from './MenuCardHeader';
import MenuCardMedia from './MenuCardMedia';

// use isNilOrEmpty util to check inventory[priceId] as it can become 0
const isOptionOutOfStock = (priceId, inventory) =>
  priceId && inventory && !isNilOrEmpty(inventory[priceId]) && isOutOfStock(inventory[priceId]);

const MenuCard = ({ t, ns, inventory }) => {
  // t wrapper that passes namespace
  const tt = tWithNamespace(t, ns);

  // empty inventory
  const emptyInventory = isNilOrEmpty(inventory);

  // prepare input for menu card header, media, and content
  const name = tt('name');
  const description = tt('description');
  const instructionLink = tt('instructionLink');
  const src = tt('image.src');
  const alt = tt('image.alt');
  const products = tt('products', { returnObjects: true });
  const stripeId = tt('stripeId', { returnObjects: true });
  const sizes = mergeAll(
    Object.keys(products).map((key) => ({
      [key]: tt(key)
    }))
  );
  const prices = mergeAll(
    Object.keys(products).map((key) => ({
      [key]: products[key].price
    }))
  );
  const options = mergeAll(
    Object.keys(products).map((key) => ({
      [key]: products[key].options.map(({ ref, disabled, checked, n }) => {
        const priceId = stripeId[key][ref];
        const outOfStock = isOptionOutOfStock(priceId, inventory);
        return {
          id: ref,
          disabled: disabled || outOfStock || emptyInventory,
          checked,
          i18nId: ref,
          i18nNs: ns,
          n,
          price: products[key].price[ref],
          stock: priceId && inventory && !isNilOrEmpty(inventory[priceId]) ? inventory[priceId] : null
        };
      })
    }))
  );
  const isProdReady = !isNilOrEmpty(products);

  return (
    <MenuCardStyled>
      <MenuCardHeader title={name} />
      <MenuCardMedia src={src} alt={alt} isProdReady={isProdReady} />
      <MenuCardContent
        ns={ns}
        stripeId={stripeId}
        description={description}
        instructionLink={instructionLink}
        sizes={sizes}
        prices={prices}
        options={options}
        inventory={inventory}
        isProdReady={isProdReady}
      />
    </MenuCardStyled>
  );
};

MenuCard.defaultProps = {
  inventory: {}
};

MenuCard.propTypes = {
  t: PropTypes.func.isRequired,
  ns: PropTypes.string.isRequired,
  inventory: PropTypes.shape({})
};

export default withTranslation()(MenuCard);
