import { Box, CardContent, IconButton } from '@mui/material';
import { styled } from '@mui/system';

export const CheckoutItemCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  },
  [theme.breakpoints.up('md')]: {
    padding: '16px',
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export const CheckoutItemActionContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: 'auto'
}));

export const DeleteIconButton = styled(IconButton)(() => ({
  margin: 0,
  padding: 0,
  marginLeft: 'auto'
}));
