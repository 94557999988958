import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { LOGISTICS_DELIVERY, LOGISTICS_PICK_UP_EVERGREEN, LOGISTICS_PICK_UP_MARDALOOP } from '../../../shared/constants';
import {
  CheckoutLogisticsMenuItem as CheckoutLogisticsMenuItemStyled,
  CheckoutLogisticsSelect as CheckoutLogisticsSelectStyled
} from './CheckoutLogisticsSelect.styles';

const CheckoutLogisticsSelect = ({ t, logistics, handleLogisticsChange }) => (
  <CheckoutLogisticsSelectStyled value={logistics} onChange={handleLogisticsChange}>
    <CheckoutLogisticsMenuItemStyled value={LOGISTICS_DELIVERY}>{t(LOGISTICS_DELIVERY)}</CheckoutLogisticsMenuItemStyled>
    <CheckoutLogisticsMenuItemStyled value={LOGISTICS_PICK_UP_EVERGREEN}>
      {t(LOGISTICS_PICK_UP_EVERGREEN)}
    </CheckoutLogisticsMenuItemStyled>
    <CheckoutLogisticsMenuItemStyled value={LOGISTICS_PICK_UP_MARDALOOP}>
      {t(LOGISTICS_PICK_UP_MARDALOOP)}
    </CheckoutLogisticsMenuItemStyled>
  </CheckoutLogisticsSelectStyled>
);

CheckoutLogisticsSelect.propTypes = {
  t: PropTypes.func.isRequired,
  logistics: PropTypes.string.isRequired,
  handleLogisticsChange: PropTypes.func.isRequired
};

export default withTranslation()(CheckoutLogisticsSelect);
