import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { MenuSizeContainer, MenuSizeFormControl, MenuSizeItem, MenuSizeSelect } from './MenuSize.styles';

const MenuSize = ({ t, sizes, size, handleSizeClick }) => (
  <MenuSizeContainer>
    <MenuSizeFormControl fullWidth>
      <InputLabel id="menu-size-label">{t('menuPage.size')}</InputLabel>
      <MenuSizeSelect
        autoWidth={false}
        labelId="menu-size-label"
        value={size}
        label={t('menuPage.size')}
        onClick={handleSizeClick}
      >
        {Object.keys(sizes).map((key) => (
          <MenuSizeItem key={key} value={key}>
            {sizes[key]}
          </MenuSizeItem>
        ))}
      </MenuSizeSelect>
    </MenuSizeFormControl>
  </MenuSizeContainer>
);

MenuSize.propTypes = {
  t: PropTypes.func.isRequired,
  sizes: PropTypes.shape({}).isRequired,
  size: PropTypes.string.isRequired,
  handleSizeClick: PropTypes.func.isRequired
};

export default withTranslation()(MenuSize);
