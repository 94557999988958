import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const FaqHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

export const FaqItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  },
  padding: theme.spacing(2, 0)
}));

export const FaqCollapsibleHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

export const FaqDivider = styled(Divider)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));
