import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Page = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 0)
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1, 0)
  },
  height: '100%',
  overflowX: 'hidden'
}));
