import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CLEAR_CART_RESPONSE } from '../../../../shared/constants';

const getClearCartResponse = () => {
  try {
    return Cookies.get(CLEAR_CART_RESPONSE);
  } catch (e) {
    return null;
  } finally {
    Cookies.remove(CLEAR_CART_RESPONSE);
  }
};

export const useClearCartResponse = (handler) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }
    setChecked(true);

    if (getClearCartResponse()) {
      handler();
    }
  }, [checked, handler]);
};
