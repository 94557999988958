import NewReleasesIcon from '@mui/icons-material/NewReleasesOutlined';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const ErrorIcon = styled(NewReleasesIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  color: theme.palette.secondary.light,
  marginBottom: theme.spacing(1)
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(12),
  marginTop: theme.spacing(6)
}));
