import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { event } from 'react-ga';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import {
  CONTACT_EMAIL_MAX_LENGTH,
  CONTACT_MESSAGE_MAX_LENGTH,
  CONTACT_NAME_MAX_LENGTH,
  GA_EVENT_CONTACT_SUBMIT,
  POST
} from '../../../shared/constants';
import { ContactForm as ContactFormStyled, ContactFormTextField, SubmitButton } from './ContactPage.styles';

const formStyle = {
  width: '100%',
  display: 'contents'
};

const ContactForm = ({ t, name, email, message, handleFormDataChange }) => {
  const [emailValidationError, setEmailValidationError] = useState(false);
  const emailRef = useRef(null);

  const handleEmailChange = (e) => {
    setEmailValidationError(false);
    handleFormDataChange('email')(e);
  };

  const handleSubmitClick = (e) => {
    const isValidEmail = validator.isEmail(emailRef.current.value || '');

    if (!isValidEmail) {
      setEmailValidationError(true);
      e.preventDefault();
    }

    event(GA_EVENT_CONTACT_SUBMIT);
  };

  return (
    <form action="/api/contact" method={POST} style={formStyle} onSubmit={handleSubmitClick}>
      <ContactFormStyled>
        <ContactFormTextField
          label={t('contactPage.name')}
          size="small"
          inputProps={{ maxLength: CONTACT_NAME_MAX_LENGTH }}
          name="name"
          value={name}
          onChange={handleFormDataChange('name')}
        />
        <ContactFormTextField
          inputRef={emailRef}
          label={t('contactPage.email')}
          type="email"
          size="small"
          helperText={emailValidationError ? t('contactPage.error.invalidEmail') : ''}
          error={emailValidationError}
          inputProps={{ maxLength: CONTACT_EMAIL_MAX_LENGTH }}
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <ContactFormTextField
          label={t('contactPage.message')}
          multiline
          rows={5}
          size="small"
          inputProps={{ maxLength: CONTACT_MESSAGE_MAX_LENGTH }}
          name="message"
          value={message}
          onChange={handleFormDataChange('message')}
        />
        <SubmitButton variant="outlined" type="submit">
          {t('contactPage.submit')}
        </SubmitButton>
      </ContactFormStyled>
    </form>
  );
};

ContactForm.defaultProps = {
  name: '',
  email: '',
  message: ''
};

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  handleFormDataChange: PropTypes.func.isRequired
};

export default withTranslation()(ContactForm);
