import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { clearCart } from '../../storage/cart';
import CheckoutPage from '../checkout-page/CheckoutPage';
import ContactPage from '../contact-page/ContactPage';
import CookieConsent from '../cookie-consent/CookieConsent';
import CreditPage from '../credit-page/CreditPage';
import FaqPage from '../faq-page/FaqPage';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import LandingPage from '../landing-page/LandingPage';
import MenuPage from '../menu-page/MenuPage';
import NotFoundPage from '../not-found-page/NotFoundPage';
import OrderPage from '../order-page/OrderPage';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';
import { useClearCartResponse } from '../shared/hooks/useClearCartResponse';
import SitemapPage from '../sitemape-page/SitemapPage';
import TermsAndConditions from '../terms-and-conditions/TermsAndConditions';
import AppLayout from './app-layout/AppLayout';
import PageContainer from './page-container/PageContainer';

const AppRouter = () => {
  useClearCartResponse(() => {
    clearCart();
  });

  return (
    <Router>
      <AppLayout>
        <Header />
        <PageContainer>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/order/:id" element={<OrderPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/credits" element={<CreditPage />} />
            <Route path="/sitemap" element={<SitemapPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageContainer>
        <Footer />
        <CookieConsent />
      </AppLayout>
    </Router>
  );
};

export default AppRouter;
