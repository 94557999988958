import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const ExpandMore = styled(({ expand, ...other }) => <IconButton {...other} />)(({ theme, expand }) => ({
  margin: 0,
  padding: 0,
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default ExpandMore;
