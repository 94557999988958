import { FormGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { splitArrayInHalf } from '../../../../shared/utils';
import { useScreenSizeDetector } from '../../shared/hooks/useScreenSizeDetector';
import MenuCardOption from './MenuCardOption';
import { MenuCardOptionCol, MenuCardOptionCollapse, MenuCardOptionDivider, MenuCardOptionRow } from './MenuCardOptions.styles';

const MenuCardOptions = ({ expanded, divider, prices, options, handleOptionClick }) => {
  const isSmallScreen = useScreenSizeDetector();
  // 1 column for smaller screen
  // 2 columns for larger screen
  const columns = isSmallScreen ? [options] : splitArrayInHalf(options);

  const columnKey = (column) => column.map(({ id }) => id).join('-');

  return (
    <>
      <MenuCardOptionCollapse in={expanded} timeout="auto" unmountOnExit>
        <FormGroup>
          <MenuCardOptionRow>
            {columns.map((column) => (
              <MenuCardOptionCol key={columnKey(column)}>
                {column.map((row) => (
                  <MenuCardOption {...row} key={row.id} prices={prices} handleOptionClick={handleOptionClick} />
                ))}
              </MenuCardOptionCol>
            ))}
          </MenuCardOptionRow>
        </FormGroup>
        {divider && <MenuCardOptionDivider variant="middle" light />}
      </MenuCardOptionCollapse>
    </>
  );
};

MenuCardOptions.defaultProps = {
  divider: false,
  options: []
};

MenuCardOptions.propTypes = {
  expanded: PropTypes.bool.isRequired,
  divider: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  prices: PropTypes.shape({}).isRequired,
  handleOptionClick: PropTypes.func.isRequired
};

export default MenuCardOptions;
