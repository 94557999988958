import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { event } from 'react-ga';
import {
  GA_EVENT_MENU_ADD_TO_CART,
  GA_EVENT_MENU_CUSTOMIZE_CLICK,
  GA_EVENT_MENU_OPTION_CHANGE,
  GA_EVENT_MENU_SIZE_CHANGE
} from '../../../../shared/constants';
import { isNilOrEmpty, OPEN_FOR_ORDER } from '../../../../shared/utils';
import { addToCart } from '../../../storage/cart';
import MenuAddToCartButton from './MenuAddToCartButton';
import {
  MenuAddToCartSection,
  MenuCardContent as MenuCardContentStyled,
  MenuSizeCustomizeSection
} from './MenuCardContent.styles';
import MenuCardOptions from './MenuCardOptions';
import MenuCustomizeButton from './MenuCustomizeButton';
import MenuDescription from './MenuDescription';
import MenuPrice from './MenuPrice';
import MenuSize from './MenuSize';

// filter out options that are disabled, unchecked by default, or has no price from base price
const getBasePrice = (prices, options) => {
  if (isNilOrEmpty(prices) || isNilOrEmpty(options)) {
    return 0;
  }

  const { base, ...others } = prices;

  return (
    options
      .filter(({ id, disabled, checked }) => !disabled && checked && others[id])
      .map(({ id }) => others[id])
      .reduce((a, b) => a + b, 0) + base
  );
};

// toggle option by the given id
const toggleOption = (options, id, checked) => options.map((option) => (option.id === id ? { ...option, checked } : option));

const MenuCardContent = ({ ns, stripeId, description, instructionLink, sizes, prices, options, inventory, isProdReady }) => {
  const size = Object.keys(sizes)[0] || '';
  const [state, setState] = useState({
    expanded: false,
    price: getBasePrice(prices[size], options[size]),
    options: options[size],
    size
  });

  const handleExpandClick = () => {
    setState({ ...state, expanded: !state.expanded });
    event(GA_EVENT_MENU_CUSTOMIZE_CLICK);
  };

  const handleOptionClick = (e) => {
    if (!e || !e.target) {
      return;
    }

    const id = e.target.getAttribute('data-id');
    if (!id) {
      return;
    }

    const optionPrice = prices[state.size][id] || 0;
    const newPrice = e.target.checked ? state.price + optionPrice : state.price - optionPrice;
    setState({
      ...state,
      price: newPrice,
      options: toggleOption(state.options, id, e.target.checked)
    });
    event(GA_EVENT_MENU_OPTION_CHANGE);
  };

  const handleSizeClick = (e) => {
    if (!e || !e.target) {
      return;
    }

    const newSize = e.target.getAttribute('data-value');
    if (isNilOrEmpty(newSize)) {
      return;
    }

    setState({
      ...state,
      price: getBasePrice(prices[newSize], options[newSize]),
      options: options[newSize],
      size: newSize
    });
    event(GA_EVENT_MENU_SIZE_CHANGE);
  };

  const handleAddToCart = () => {
    addToCart({
      ns,
      price: state.price,
      size: state.size,
      prices: prices[state.size],
      options: state.options.filter((option) => !option.disabled)
    });
    event(GA_EVENT_MENU_ADD_TO_CART);
  };

  return (
    <MenuCardContentStyled>
      <>
        {!isNilOrEmpty(description) && <MenuDescription description={description} instructionLink={instructionLink} />}
        {isProdReady && OPEN_FOR_ORDER && (
          <>
            <MenuSizeCustomizeSection>
              <MenuSize sizes={sizes} size={state.size} handleSizeClick={handleSizeClick} />
              <MenuCustomizeButton expanded={state.expanded} handleExpandClick={handleExpandClick} />
            </MenuSizeCustomizeSection>
            <MenuCardOptions
              expanded={state.expanded}
              divider
              prices={prices[size]}
              options={state.options}
              handleOptionClick={handleOptionClick}
            />
            <MenuAddToCartSection>
              <MenuPrice price={state.price} stock={inventory[stripeId[state.size].base]} />
              <MenuAddToCartButton handleAddToCart={handleAddToCart} stock={inventory[stripeId[state.size].base]} />
            </MenuAddToCartSection>
          </>
        )}
      </>
    </MenuCardContentStyled>
  );
};

MenuCardContent.defaultProps = {
  description: '',
  instructionLink: ''
};

MenuCardContent.propTypes = {
  ns: PropTypes.string.isRequired,
  stripeId: PropTypes.shape({}).isRequired,
  description: PropTypes.string,
  instructionLink: PropTypes.string,
  sizes: PropTypes.shape({}).isRequired,
  prices: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  inventory: PropTypes.shape({}).isRequired,
  isProdReady: PropTypes.bool.isRequired
};

export default MenuCardContent;
