import { isProductionMode } from '../../shared/utils';
import stripeId from './stripe-id.json';

const { prod, test } = stripeId;

const translation = {
  en: isProductionMode() ? prod.en : test.en,
  ko: isProductionMode() ? prod.ko : test.ko
};

export default translation;
