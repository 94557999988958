import PropTypes from 'prop-types';
import React from 'react';
import { SnsIconButton as SnsIconButtonStyled } from './SnsIconButton.styles';

const SnsIconButton = ({ href, icon }) => (
  <SnsIconButtonStyled href={href} target="_blank" rel="noopener noreferrer">
    {icon}
  </SnsIconButtonStyled>
);

SnsIconButton.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired
};

export default SnsIconButton;
