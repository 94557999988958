import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { OPEN_FOR_ORDER } from '../../../../shared/utils';
import { MenuCardComingSoonImage, MenuCardImage, MenuCardMedia as MenuCardMediaStyled } from './MenuCardMedia.styles';

const MenuCardMedia = ({ t, src, alt, isProdReady }) => {
  const theme = useTheme();
  const blurEffectForComingSoon =
    isProdReady && OPEN_FOR_ORDER ? {} : { filter: `blur(${theme.spacing(0.25)}px)`, opacity: '0.75' };

  return (
    <MenuCardMediaStyled>
      <MenuCardImage variant="square" src={src} alt={alt} sx={blurEffectForComingSoon} />
      {(!isProdReady || !OPEN_FOR_ORDER) && (
        <MenuCardComingSoonImage variant="square" src={t('comingSoon.src')} alt={t('comingSoon.alt')} />
      )}
    </MenuCardMediaStyled>
  );
};

MenuCardMedia.propTypes = {
  t: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isProdReady: PropTypes.bool.isRequired
};

export default withTranslation()(MenuCardMedia);
