import PropTypes from 'prop-types';
import React from 'react';
import { isNilOrEmpty } from '../../../../shared/utils';
import Link from '../../shared/Link';
import { MenuDescription as MenuDescriptionStyled } from './MenuDescription.styles';

const MenuDescription = ({ description, instructionLink }) => (
  <>
    <MenuDescriptionStyled variant="body2" gutterBottom>
      {description}
      {!isNilOrEmpty(instructionLink) && (
        <Link href={instructionLink} text={instructionLink} openNewTab variant="body2" sx={{ textDecoration: 'underline' }} />
      )}
    </MenuDescriptionStyled>
  </>
);

MenuDescription.propTypes = {
  description: PropTypes.string.isRequired,
  instructionLink: PropTypes.string.isRequired
};

export default MenuDescription;
