import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { removeAllNonLetters } from '../../../shared/utils';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import CreditLink from './CreditLink';
import { CreditContainer, CreditTypography } from './CreditPage.styles';

const tparams = { returnObjects: true };

const CreditPage = ({ t }) => (
  <Page>
    <CreditContainer>
      {/* logo credits */}
      <CreditTypography gutterBottom variant="body2" color="secondary.light">
        {t('creditPage.logo.text1')}
        <CreditLink {...t('creditPage.logo.bsd', tparams)} />
        {t('creditPage.logo.and')}
        <CreditLink {...t('creditPage.logo.apien', tparams)} />
        {t('creditPage.logo.text2')}
      </CreditTypography>
      {/* landing page background icon credits */}
      <CreditTypography gutterBottom variant="body2" color="secondary.light">
        {t('creditPage.lpIcons.text1')}
        <CreditLink {...t('creditPage.lpIcons.kerismaker', tparams)} />
        {t('creditPage.lpIcons.and')}
        {`${t('creditPage.lpIcons.Freepik', tparams)[0].alt}: `}
        {t('creditPage.lpIcons.Freepik', tparams).map(({ href }, index) => (
          <Box component="span" key={removeAllNonLetters(href)}>
            <CreditLink href={href} alt={(index + 1).toString()} />
            {' '}
          </Box>
        ))}
        {t('creditPage.lpIcons.text2')}
      </CreditTypography>
      {/* landing page background image credits */}
      <CreditTypography gutterBottom variant="body2" color="secondary.light">
        {t('creditPage.lpBackground.text1')}
        <CreditLink {...t('creditPage.lpBackground.nikluv', tparams)} />
        {t('creditPage.lpBackground.text2')}
      </CreditTypography>
    </CreditContainer>
  </Page>
);

CreditPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withGAPageview()(withTranslation()(withHelmetTitle()(CreditPage)));
