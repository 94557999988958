import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Link from '../../shared/Link';
import { LinkList as LinkListStyled } from './LinkList.styles';
import VerticalDivider from './VerticalDivider';

const LinkList = ({ t }) => (
  <LinkListStyled>
    <Link href="/terms-and-conditions" text={t('footer.termsAndConditions')} openNewTab={false} />
    <VerticalDivider />
    <Link href="/privacy-policy" text={t('footer.privacyPolicy')} openNewTab={false} />
    <VerticalDivider />
    <Link href="/credits" text={t('footer.credits')} openNewTab={false} />
    <VerticalDivider />
    <Link href="/sitemap" text={t('footer.sitemap')} openNewTab={false} />
  </LinkListStyled>
);

LinkList.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(LinkList);
