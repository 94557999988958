import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CreditContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    minWidth: '85%',
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm
  },
  padding: theme.spacing(2, 0),
  '& >:not(:last-child)': {
    paddingBottom: theme.spacing(1)
  }
}));

export const CreditTypography = styled(Typography)(() => ({
  lineHeight: 1.2
}));
