import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { event } from 'react-ga';
import { withTranslation } from 'react-i18next';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_WARNING,
  DELIVERY_FEE,
  DELIVERY_THRESHOLD,
  GA_EVENT_CHECKOUT_FAILURE,
  LOGISTICS_DELIVERY,
  LOGISTICS_PICK_UP_EVERGREEN,
  LOGISTICS_PICK_UP_MARDALOOP
} from '../../../shared/constants';
import {
  DELIVERY_DAY,
  dollarize,
  isAfterCutOff,
  isBeforeCutOff,
  NEXT_DELIVERY_DAY,
  OPEN_FOR_ORDER,
  tWithNamespace
} from '../../../shared/utils';
import { getCart, isCartEmpty } from '../../storage/cart';
import { getLogistics, setLogistics } from '../../storage/logistics';
import AlertBanner from '../shared/AlertBanner';
import { AlertBannerContainer } from '../shared/AlertBanner.styles';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import { withSnackbar } from '../shared/hoc/withSnackbar';
import { useCheckoutResponse } from '../shared/hooks/useCheckoutResponse';
import { useScreenSizeDetector } from '../shared/hooks/useScreenSizeDetector';
import { useStorage } from '../shared/hooks/useStorage';
import Link from '../shared/Link';
import CheckoutItemCard from './checkout-item-card/CheckoutItemCard';
import CheckoutButton from './CheckoutButton';
import CheckoutLogisticsSelect from './CheckoutLogisticsSelect';
import {
  CheckoutPageHeaderContainer as CheckoutPageHeaderContainerStyled,
  CheckoutPagePriceButtonContainer as CheckoutPagePriceButtonContainerStyled
} from './CheckoutPage.styles';
import EmptyCheckoutPage from './EmptyCheckoutPage';
import TotalPrice from './TotalPrice';

const CheckoutPage = ({ t, setSnackbar }) => {
  const getTotalPrice = () => {
    if (isCartEmpty()) {
      return 0;
    }
    // use up-to-date pricing to calculate total price
    return getCart()
      .map((item) => {
        const { ns, size, options } = item;
        // t wrapper that passes namespace
        const tt = tWithNamespace(t, ns);
        // get stripe id based on i18n namespace
        const products = tt('products', { returnObjects: true });
        // calculate item price including checked options
        let price = products[size].price.base;
        price += options.map(({ id, checked }) => (checked ? products[size].price[id] : 0)).reduce((a, b) => a + b);
        return { ...item, price, prices: products[size].price };
      })
      .reduce((a, b) => ({ price: a.price + b.price })).price;
  };

  const [state, setState] = useState({
    loaded: false,
    cart: getCart(),
    total: getTotalPrice(),
    logistics: getLogistics()
  });

  const isSmallScreen = useScreenSizeDetector();
  const isExtraSmallScreen = useScreenSizeDetector('sm');

  useStorage(() => {
    setState({
      ...state,
      cart: getCart(),
      total: getTotalPrice()
    });
  });

  useCheckoutResponse((responseType) => {
    setSnackbar({
      message: t(`notification.${responseType}`),
      severity: ALERT_SEVERITY_ERROR
    });
    event({ ...GA_EVENT_CHECKOUT_FAILURE, label: responseType });
  });

  const translate = ({ mon, day, dow }) => ({
    mon: t(`${isSmallScreen ? 'mon' : 'month'}.${mon}`),
    day: t(day),
    dow: t(`${isSmallScreen ? 'dowShort' : 'dow'}.${dow}`)
  });

  const handleLogisticsChange = (e) => {
    setLogistics(e.target.value);
    setState({
      ...state,
      logistics: e.target.value
    });
  };

  return (
    <Page>
      <AlertBannerContainer>
        {/* <AnnouncementBanner /> */}
        {OPEN_FOR_ORDER && isAfterCutOff() ? (
          <AlertBanner
            message={t('checkoutPage.banner.deliveryAfterCutOff', translate(NEXT_DELIVERY_DAY))}
            severity={ALERT_SEVERITY_WARNING}
            iconMapping={{
              warning: <LocalShippingIcon fontSize="inherit" />
            }}
          />
        ) : (
          <AlertBanner
            message={t('checkoutPage.banner.deliveryBeforeCutOff', translate(DELIVERY_DAY))}
            severity={ALERT_SEVERITY_INFO}
            iconMapping={{
              info: <LocalShippingIcon fontSize="inherit" />
            }}
          />
        )}
        {!OPEN_FOR_ORDER && <AlertBanner message={t('landingPage.alert.openingSoon')} severity={ALERT_SEVERITY_INFO} />}
        {state.logistics === LOGISTICS_PICK_UP_EVERGREEN && state.total > 0 && (
          <AlertBanner message={t('checkoutPage.banner.pickupEvergreen')} severity={ALERT_SEVERITY_WARNING} />
        )}
        {state.logistics === LOGISTICS_PICK_UP_MARDALOOP && state.total > 0 && isAfterCutOff() && (
          <AlertBanner
            message={t('checkoutPage.banner.pickupMardaLoop', translate(NEXT_DELIVERY_DAY))}
            severity={ALERT_SEVERITY_WARNING}
            link={
              <Link
                href={t('checkoutPage.market.direction.href')}
                text={t('checkoutPage.market.direction.text')}
                openNewTab
                variant="body2"
                sx={{ textDecoration: 'underline' }}
              />
            }
          />
        )}
        {state.logistics === LOGISTICS_PICK_UP_MARDALOOP && state.total > 0 && isBeforeCutOff() && (
          <AlertBanner
            message={t('checkoutPage.banner.pickupMardaLoop', translate(DELIVERY_DAY))}
            severity={ALERT_SEVERITY_WARNING}
            link={
              <Link
                href={t('checkoutPage.market.direction.href')}
                text={t('checkoutPage.market.direction.text')}
                openNewTab
                variant="body2"
                sx={{ textDecoration: 'underline' }}
              />
            }
          />
        )}
        {state.logistics === LOGISTICS_DELIVERY && state.total > 0 && state.total < DELIVERY_THRESHOLD && (
          <AlertBanner
            message={t('checkoutPage.banner.deliveryFee', {
              fee: dollarize(DELIVERY_FEE),
              threshold: dollarize(DELIVERY_THRESHOLD)
            })}
            severity={ALERT_SEVERITY_WARNING}
          />
        )}
        {state.logistics === LOGISTICS_DELIVERY && state.total > 0 && state.total >= DELIVERY_THRESHOLD && (
          <AlertBanner
            message={t('checkoutPage.banner.freeDelivery', {
              threshold: dollarize(DELIVERY_THRESHOLD)
            })}
            severity={ALERT_SEVERITY_INFO}
          />
        )}
      </AlertBannerContainer>
      <CheckoutPageHeaderContainerStyled>
        {isExtraSmallScreen && (
          <CheckoutLogisticsSelect logistics={state.logistics} handleLogisticsChange={handleLogisticsChange} />
        )}
        <CheckoutPagePriceButtonContainerStyled>
          <TotalPrice total={state.total} />
          {!isExtraSmallScreen && (
            <CheckoutLogisticsSelect logistics={state.logistics} handleLogisticsChange={handleLogisticsChange} />
          )}
          <CheckoutButton logistics={state.logistics} disabled={isCartEmpty() || !OPEN_FOR_ORDER} />
        </CheckoutPagePriceButtonContainerStyled>
      </CheckoutPageHeaderContainerStyled>
      {isCartEmpty() && <EmptyCheckoutPage />}
      {state.cart.map((item) => {
        const { ns, size, options } = item;
        // t wrapper that passes namespace
        const tt = tWithNamespace(t, ns);
        // get stripe id based on i18n namespace
        const products = tt('products', { returnObjects: true });
        // use up-to-date pricing
        let price = products[size].price.base;
        price += options.map(({ id, checked }) => (checked ? products[size].price[id] : 0)).reduce((a, b) => a + b);
        return <CheckoutItemCard key={item.id} {...item} price={price} prices={products[size].price} />;
      })}
    </Page>
  );
};

CheckoutPage.propTypes = {
  t: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired
};

export default withGAPageview()(withSnackbar()(withTranslation()(withHelmetTitle()(CheckoutPage))));
