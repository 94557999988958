import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { dollarize } from '../../../shared/utils';
import { TotalPrice as TotalPriceStyled } from './TotalPrice.styles';

const TotalPrice = ({ t, total }) => (
  <TotalPriceStyled variant="h6" color="secondary.light">
    {t('checkoutPage.total', { total: dollarize(total) })}
  </TotalPriceStyled>
);

TotalPrice.propTypes = {
  t: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default withTranslation()(TotalPrice);
