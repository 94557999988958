import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import SnsIconButton from './SnsIconButton';
import { SnsList as SnsListStyled, TiktokSvgIcon } from './SnsList.styles';

const SnsList = ({ t }) => (
  <>
    <Typography variant="caption" component="span">
      {t('footer.followUsOn')}
    </Typography>
    <SnsListStyled>
      <SnsIconButton href={t('sns.instagram')} icon={<InstagramIcon color="offwhite" />} />
      <SnsIconButton href={t('sns.tiktok')} icon={<TiktokSvgIcon />} />
      <SnsIconButton href={t('sns.twitter')} icon={<TwitterIcon color="offwhite" />} />
      {/* <SnsIconButton href={t('sns.facebook')} icon={<FacebookIcon color="offwhite" />} /> */}
    </SnsListStyled>
  </>
);

SnsList.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SnsList);
