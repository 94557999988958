import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { removeAllNonLetters } from '../../../shared/utils';
import { Page } from '../shared/CommonPage.Styles';
import { withGAPageview } from '../shared/hoc/withGAPageview';
import { withHelmetTitle } from '../shared/hoc/withHelmetTitle';
import FaqItem from './FaqItem';
import { FaqHeader } from './FaqItem.styles';

const FaqPage = ({ t }) => (
  <Page>
    <FaqHeader variant="h5" color="secondary.light">
      {t('faqPage.faq')}
    </FaqHeader>
    {t('faqPage.faqs', { returnObjects: true }).map(({ q, a }) => (
      <FaqItem key={removeAllNonLetters(q)} question={q} answer={a} />
    ))}
  </Page>
);

FaqPage.propTypes = {
  t: PropTypes.func.isRequired
};

export default withGAPageview()(withTranslation()(withHelmetTitle()(FaqPage)));
