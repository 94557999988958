import { Tab } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const MenubarTab = ({ value, label, onClick, href }) => (
  <Tab value={value} label={label} onClick={onClick} component="a" href={href} />
);

MenubarTab.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired
};

export default MenubarTab;
