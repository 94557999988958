import { Tabs } from '@mui/material';
import { styled } from '@mui/system';

export const MenubarTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    width: theme.breakpoints.values.sm,
    justifyContent: 'space-between'
  },
  '& .MuiTabs-indicator': {
    height: theme.spacing(0.5),
    top: 0
  }
}));
