import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenuOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import TranslateIcon from '@mui/icons-material/TranslateOutlined';
import { Collapse, Drawer, List } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_EN, LANGUAGE_KO } from '../../../../../../shared/constants';
import { getLanguage } from '../../../../../../shared/utils';
import DrawerListDivider from './DrawerListDivider';
import DrawerListItem from './DrawerListItem';

const MenuDrawer = ({ t, i18n, drawer: open, toggleDrawer }) => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const closeMenuDrawer = toggleDrawer(false);

  const changeLanguageToEn = () => {
    i18n.changeLanguage(LANGUAGE_EN);
    setLanguageMenuOpen(false);
    closeMenuDrawer();
  };

  const changeLanguageToKo = () => {
    i18n.changeLanguage(LANGUAGE_KO);
    setLanguageMenuOpen(false);
    closeMenuDrawer();
  };

  const handleLanguageExpandClick = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  return (
    <Drawer anchor="top" open={open} onClose={closeMenuDrawer}>
      <List>
        <DrawerListItem icon={<InfoIcon />} text={t('menu.about')} href="/" />
        <DrawerListItem icon={<RestaurantMenuIcon />} text={t('menu.menu')} href="/menu" />
        <DrawerListDivider />
        <DrawerListItem icon={<TranslateIcon />} text={t('menu.language')} onClick={handleLanguageExpandClick}>
          {languageMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </DrawerListItem>
        <Collapse in={languageMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {getLanguage(i18n) !== LANGUAGE_EN && <DrawerListItem text={t('menu.en')} onClick={changeLanguageToEn} />}
            {getLanguage(i18n) !== LANGUAGE_KO && <DrawerListItem text={t('menu.ko')} onClick={changeLanguageToKo} />}
          </List>
        </Collapse>
        <DrawerListItem icon={<HelpIcon />} text={t('menu.faq')} href="/faq" />
        <DrawerListItem icon={<SupportAgentIcon />} text={t('menu.contact')} href="/contact" />
      </List>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    changeLanguage: PropTypes.func
  }).isRequired,
  drawer: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default withTranslation()(MenuDrawer);
