import { CardMedia } from '@mui/material';
import { styled } from '@mui/system';

export const CheckoutItemCardMedia = styled(CardMedia)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(16),
    height: theme.spacing(16)
  }
}));
