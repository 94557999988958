import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { dollarize, isOutOfStock, isStockLow } from '../../../../shared/utils';
import { StockTypography } from './MenuPrice.styles';

const MenuPrice = ({ t, price, stock }) => (
  <>
    <Typography variant="h6">{dollarize(price)}</Typography>
    {isOutOfStock(stock) && (
      <StockTypography variant="body1" color="secondary.light">
        {`(${t('menuPage.outOfStock')})`}
      </StockTypography>
    )}
    {isStockLow(stock) && (
      <StockTypography variant="body1" color="secondary.light">
        {`(${t('menuPage.lowStock', { stock })})`}
      </StockTypography>
    )}
  </>
);

MenuPrice.defaultProps = {
  stock: 0
};

MenuPrice.propTypes = {
  t: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  stock: PropTypes.number
};

export default withTranslation()(MenuPrice);
