import { Card } from '@mui/material';
import { styled } from '@mui/system';

export const MenuCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: theme.palette.offwhite.main,
  [theme.breakpoints.down('md')]: {
    maxWidth: '85%'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.breakpoints.values.sm
  }
}));
