import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle, Collapse, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AlertBanner as AlertBannerStyled, AlertStyled } from './AlertBanner.styles';

const AlertBanner = ({ title, message, severity, iconMapping, link }) => {
  const [open, setOpen] = useState(true);

  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <AlertBannerStyled>
      <Collapse in={open}>
        <AlertStyled
          severity={severity}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseClick}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          iconMapping={iconMapping}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
          {link && <> {link}</>}
        </AlertStyled>
      </Collapse>
    </AlertBannerStyled>
  );
};

AlertBanner.defaultProps = {
  title: '',
  iconMapping: {},
  link: null
};

AlertBanner.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  severity: PropTypes.string.isRequired,
  iconMapping: PropTypes.shape({}),
  link: PropTypes.shape({})
};

export default AlertBanner;
