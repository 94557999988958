import React, { useState } from 'react';
import { getWrappedComponentDisplayName } from '../../../../shared/utils';
import { BackdropStyled, CircularProgressStyled } from './withProgress.styles';

const withProgress = () => (WrappedComponent) => {
  const WithProgress = () => {
    const [open, setOpen] = useState(false);

    const setProgress = (progress) => {
      setOpen(progress);
    };

    return (
      <>
        <BackdropStyled open={open}>
          <CircularProgressStyled color="inherit" size={30} thickness={5} />
        </BackdropStyled>
        <WrappedComponent setProgress={setProgress} />
      </>
    );
  };

  WithProgress.displayName = `WithProgress(${getWrappedComponentDisplayName(WrappedComponent)})`;

  return WithProgress;
};

export { withProgress };
