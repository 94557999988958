import React from 'react';
import Copyright from './copyright/Copyright';
import { Footer as FooterStyled } from './Footer.styles';
import LinkList from './link-list/LinkList';
import SnsList from './sns-list/SnsList';

const Footer = () => (
  <FooterStyled bgcolor="primary.main" color="primary.contrastText">
    <SnsList />
    <Copyright />
    <LinkList />
  </FooterStyled>
);

export default Footer;
